import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-loading-skeleton/dist/skeleton.css';
import 'animate.css';
import '../styles/globals.scss';

import Frame from 'components/Frame';
import store from 'store';
import { TouchBackend } from 'react-dnd-touch-backend';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { isMobile } from 'react-device-detect';
import ReinforcementNotificationModalContextProvider from '@contexts/reinforcement-notification-modal-context';
import GoogleAnalytics from 'components/GoogleAnalytics';

function MyApp({ Component, pageProps }) {
	const lerero_lms = store.get('lerero_lms');
	if (lerero_lms) {
		lerero_lms.alreadyRefresh = false;
		store.set('lerero_lms', lerero_lms);
	}

	return (
		<>
			<GoogleAnalytics />
			<DndProvider backend={isMobile ? TouchBackend : HTML5Backend}>
				<ReinforcementNotificationModalContextProvider>
					<Frame>
						<Component {...pageProps} />
					</Frame>
				</ReinforcementNotificationModalContextProvider>
			</DndProvider>
		</>
	);
}

// MyApp.getInitialProps = async (appContext) => {
//   const { ctx, router } = appContext;

//   // if (router.route.includes("/admin/") && !amsi) {
//   //   // Redirect to login page if not authenticated
//   //   ctx.res.writeHead(302, { Location: "/" });
//   //   ctx.res.end();
//   // }
//   return {};
// };

export default MyApp;
