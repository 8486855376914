import { useMemo } from 'react';
import { ReinforcementActivityType } from 'types/DTOs/activityDTOs';

const CUSTOM_ACTIVITY_COMPLETIONS: ReinforcementActivityType[] = [
	'assignment',
	'quiz',
	'drag_drop',
	'scramble_sentences',
	'ordering',
];

export const useShowLearnerCompletionButton = (activityType: ReinforcementActivityType, canBeCompleted: boolean) => {
	return useMemo(
		() => canBeCompleted || !CUSTOM_ACTIVITY_COMPLETIONS.includes(activityType),
		[canBeCompleted, activityType]
	);
};
