import React, { useEffect, useState } from 'react';

const PDFPreviewContainer = ({ children, filePath }) => {
	const [isPDF, setIsPDF] = useState(false);

	useEffect(() => {
		if (filePath) {
			const getExtension = (url) => {
				const extStart = url.indexOf('.', url.lastIndexOf('/') + 1);
				if (extStart === -1) return false;

				return url.substring(extStart + 1).toLowerCase();
			};

			if (getExtension(filePath) === 'pdf') {
				setIsPDF(true);
			}
		}
	}, [filePath]);

	if (isPDF) {
		return <iframe src={filePath} className="w-100" style={{ height: '60vh' }}></iframe>;
	}

	return children;
};

export default PDFPreviewContainer;
