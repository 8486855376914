import Feedback from 'components/Reinforcements/Feedback';
import FeedbackMarkIcon from 'components/Reinforcements/FeedbackMarkIcon';
import React, { Dispatch, FC, SetStateAction, useMemo } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import {
	AnswerFeedback,
	DragDropAnswerLearnerDTO,
	DragDropLearnerDTO,
	ReferenceModule,
} from 'types/DTOs/learnerActivityDTOs';

const DraggableCard = ({ text, onDrop }: { text: string; onDrop: Dispatch<SetStateAction<string>> }) => {
	const [{ isDragging }, drag] = useDrag(() => ({
		type: 'box',
		item: { text },
		end: (item, monitor) => {
			const dropResult = monitor.getDropResult<DragDropAnswerLearnerDTO>();
			if (item && dropResult) {
				onDrop(dropResult.answer);
			}
		},
		collect: (monitor) => ({
			isDragging: monitor.isDragging(),
			handlerId: monitor.getHandlerId(),
		}),
	}));

	const opacity = isDragging ? 0.4 : 1;

	return (
		<div className={'dd-card'} style={{ opacity }}>
			<img src="/global/icon_drag_drop.svg" id="drag-img" ref={drag} data-testid={`box`} />
			<p id="question">{text}</p>
		</div>
	);
};

const DroppableCard = ({
	answer,
	selected,
	feedback,
}: Pick<DragDropAnswerLearnerDTO, 'answer'> & { selected: boolean; feedback: any }) => {
	const [collect, drop] = useDrop(() => ({
		accept: 'box',
		drop: () => ({ answer }),
	}));

	const feedbackClass = feedback && selected ? (feedback.isCorrectAnswer ? 'correct-card' : 'wrong-card') : '';
	const markType = useMemo(() => {
		if (feedback) {
			if (feedback.isCorrectAnswer) {
				return 'correct';
			}
			return 'wrong';
		}
		return 'neutral';
	}, [selected, feedback]);

	return (
		<div className={`dd-card ${feedbackClass}`} ref={drop} id={selected ? 'selected-card' : ''}>
			{selected && <FeedbackMarkIcon type={markType} />}
			<p>{answer}</p>
		</div>
	);
};

interface IDragDrop {
	data: DragDropLearnerDTO;
	feedback: AnswerFeedback;
	selected: string;
	referenceModule?: ReferenceModule;
	setSelected: (val: string) => void;
}

const DragDrop: FC<IDragDrop> = ({ data, feedback, selected, referenceModule, setSelected }) => {
	return (
		<div className="drag-drop">
			<div className="card-container">
				<DraggableCard text={data.question} onDrop={setSelected} />
				<div className="answer-container">
					{data.answers.map((answer, indexAnswer) => (
						<div>
							<DroppableCard
								answer={answer.answer}
								selected={selected === answer.answer}
								feedback={feedback}
								key={indexAnswer}
							/>
							{feedback && selected === answer.answer ? (
								<p className={`${feedback.isCorrectAnswer ? 'correct-answer' : 'wrong-answer'} answer-type`}>
									{feedback.isCorrectAnswer ? 'Correct Answer' : 'Wrong Answer'}
								</p>
							) : null}
						</div>
					))}
				</div>
			</div>
			<Feedback feedback={feedback} referenceModule={referenceModule} />
		</div>
	);
};

export default DragDrop;
