import React, { FC } from 'react';
import {
	DndContext,
	closestCenter,
	useSensor,
	useSensors,
	DragEndEvent,
	MouseSensor,
	TouchSensor,
} from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import OrderedItem from './OrderedItem';
import { OrderedItemType } from 'types/activities';
import { AnswersFeedback, ReferenceModule } from 'types/DTOs/learnerActivityDTOs';
import Feedback from 'components/Reinforcements/Feedback';
import FeedbackMarkIcon from 'components/Reinforcements/FeedbackMarkIcon';
import { SORTABLE_MOUSE_DELAY } from '@constants/common';

interface IOrderingFields {
	items: Array<OrderedItemType>;
	feedback?: AnswersFeedback;
	showFeedback?: boolean;
	referenceModule?: ReferenceModule;
	handleDragEnd: (event: DragEndEvent) => void;
	renderItemComponent?: (item, index) => React.ReactNode;
}

const OrderingFields: FC<IOrderingFields> = ({
	items,
	feedback,
	showFeedback = true,
	referenceModule,
	handleDragEnd,
	renderItemComponent,
}) => {
	const sensors = useSensors(
		useSensor(MouseSensor, {
			activationConstraint: {
				delay: SORTABLE_MOUSE_DELAY,
				distance: 8,
				tolerance: 0,
			},
		}),
		useSensor(TouchSensor, {
			activationConstraint: {
				delay: SORTABLE_MOUSE_DELAY,
				tolerance: 6,
			},
		})
	);

	return (
		<DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
			<SortableContext items={items} strategy={verticalListSortingStrategy} disabled={!!feedback?.length}>
				{items.map((item, index) => (
					<div className="sortable-field-container" key={item.id}>
						{feedback?.length === 0 && <span>{index + 1}</span>}
						{renderItemComponent ? (
							<OrderedItem item={item}>{renderItemComponent(item, index)}</OrderedItem>
						) : (
							<div className="sequence-row ordering-field-with-feedback">
								<div className="row-with-feedback-mark">
									{!!feedback?.length && <span>{index + 1}</span>}
									{!!feedback?.length && (
										<div className="feedback-mark">
											<FeedbackMarkIcon type={feedback[index].isCorrectAnswer ? 'correct' : 'wrong'} />
										</div>
									)}
									<OrderedItem item={item} />
								</div>
								{!!feedback?.length && showFeedback && (
									<Feedback feedback={feedback[index]} referenceModule={referenceModule} />
								)}
							</div>
						)}
					</div>
				))}
			</SortableContext>
		</DndContext>
	);
};

export default OrderingFields;
