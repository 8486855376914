import React from 'react';
import { IoMdCheckmarkCircle, IoMdCloseCircle } from 'react-icons/io';

const FeedbackMarkIcon = ({ type }: { type: 'correct' | 'wrong' | 'neutral' }) =>
	type === 'correct' ? (
		<IoMdCheckmarkCircle size={20} color="#09D8BF" />
	) : type === 'wrong' ? (
		<IoMdCloseCircle size={20} color="#FF579C" />
	) : (
		<IoMdCheckmarkCircle size={20} color="#066bfb" />
	);

export default FeedbackMarkIcon;
