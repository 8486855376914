import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Font } from './NotificationWrapper';
import truncateString from 'utils/truncate_string';
import ActivityModal from '../Reinforcements/ActivityModal';
import { ReinforcementActivityExtraNotification } from 'types/DTOs/learnerActivityDTOs';
import { ReinforcementNotificationModalContext } from '@contexts/reinforcement-notification-modal-context';

type NotificationItemProps = {
	picture: { path: string };
	title: string;
	description: string;
	sendAt: string;
	isRead: string;
	onChangeStatus: (status: { id: string; read: boolean }) => void;
	_id: string;
	fullName: string;
	extra: ReinforcementActivityExtraNotification;
	allUnreadReinforcements?: ReinforcementActivityExtraNotification[] | null;
	closeNotificationPanel: () => void;
};

const ReinforcementActivityNotificationItem = ({
	picture,
	title,
	description,
	sendAt,
	isRead,
	onChangeStatus,
	_id,
	extra,
	fullName,
	allUnreadReinforcements,
	closeNotificationPanel,
}: NotificationItemProps) => {
	const [showReinforcementAlert, setShowReinforcementAlert] = useContext(ReinforcementNotificationModalContext);
	const [isViewedAll, setIsViewedAll] = useState(false);
	const [readNotification, setReadNotification] = useState<string>('');
	const [clickedNotification, setClickedNotification] = useState(false);

	description = description.replace('{activity_name}', extra?.reinforcementActivityName);
	description = description.replace('{user-name}', fullName);

	title = title.replace('{activity_name}', extra?.reinforcementActivityName);

	const specificActivityParams = useMemo(
		() => ({
			reinforcementID: extra?.reinforcementID,
			activityID: extra?.activityID,
			activityName: extra?.reinforcementActivityName,
		}),
		[extra]
	);

	const isUnreadNotification = extra?.reinforcement && !isRead;

	useEffect(() => {
		if (isUnreadNotification && _id !== readNotification) setShowReinforcementAlert(_id);
	}, [isUnreadNotification, _id, readNotification]);

	const changeStatus = useCallback(
		({ id, read }) => {
			setReadNotification(id);
			onChangeStatus({ id, read });
		},
		[onChangeStatus]
	);

	return (
		<>
			<div
				className={`d-flex notification__item gap-2 ${isRead ? 'read' : 'unread'} `}
				onClick={() => {
					if (!isRead) {
						setShowReinforcementAlert(_id);
						setClickedNotification(true);
						closeNotificationPanel();
					}
				}}
			>
				<div className="profile-image">
					<img src={picture[0]?.path || '/navbar/avatar_dummy.svg'} className="w-100 h-100 object-fit-cover" />
				</div>
				<div className="ms-3 flex-grow-1">
					<Font size="1.6rem" className="mb-1">
						{title}
					</Font>
					<div
						className="mb-4 ck-content"
						dangerouslySetInnerHTML={{
							// @ts-ignore
							__html: isViewedAll ? description : truncateString({ string: description, limit: 150, withDot: false }),
						}}
					/>

					<div className="d-flex justify-content-between">
						<Font
							size="1.4rem"
							weight="400"
							color="#066BFB"
							className="link"
							onClick={() => setIsViewedAll(!isViewedAll)}
						>
							View {isViewedAll ? 'less' : 'more'}
						</Font>
						<Font size="1.4rem" weight="300">
							{sendAt}
						</Font>
					</div>
				</div>
			</div>
			{showReinforcementAlert === _id ? (
				<ActivityModal
					showSpecificActivity={!clickedNotification ? null : { ...specificActivityParams, notificationId: _id }}
					activities={isUnreadNotification ? allUnreadReinforcements : []}
					showModal={showReinforcementAlert === _id}
					handleModalClose={() => setShowReinforcementAlert('')}
					pendingActivityDescription={isUnreadNotification ? description : null}
					markRead={changeStatus}
				/>
			) : null}
		</>
	);
};

export default ReinforcementActivityNotificationItem;
