import { API_ROUTES } from '@constants/api';
import { api, isErrorAPIType } from '@utils/api';
import { useOrderedFields } from '@utils/hooks/useOrderedFields';
import Button from 'components/Button';
import OrderingFields from 'components/CourseManagement/Ordering/OrderingFields';
import LearnerActivityDescription from 'components/LearnerActivityDescription';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { ReferenceModule } from 'types/DTOs/learnerActivityDTOs';
import { EditOrderedItemType } from 'types/activities';

interface IAbstractOrdering {
	activityId: string;
	completion: string;
	name: string;
	description: string;
	items: EditOrderedItemType[];
	referenceModule: ReferenceModule;
	closeModal: () => void;
	readNotification: () => void;
}

const AbstractOrdering: FC<IAbstractOrdering> = ({
	activityId,
	completion,
	name,
	description,
	items,
	referenceModule,
	closeModal,
	readNotification,
}) => {
	const [orderedFields, setOrderedFields] = useState([]);
	const { fields, initializeFields, handleDragEnd } = useOrderedFields(setOrderedFields);
	const [feedback, setFeedback] = useState(null);

	useEffect(() => {
		initializeFields(items);
	}, [items]);

	const handleSubmit = useCallback(async () => {
		const payload = {
			reorderingId: activityId,
			completion,
			submittedReordering: orderedFields.map((item) => ({
				sequence: item.sequence,
				orderContentId: item._id,
			})),
		};

		const res = await api.post({ endpoint: API_ROUTES.submitOrdering, payload });
		await readNotification();
		if (!isErrorAPIType(res)) {
			setFeedback(res.data.submittedReordering);
		}
	}, [orderedFields]);

	return (
		<div>
			<LearnerActivityDescription title={name} text={description} />
			<OrderingFields
				items={fields}
				feedback={feedback}
				referenceModule={referenceModule}
				handleDragEnd={handleDragEnd}
			/>
			<div className="submit-btns-container">
				<div className={!feedback ? '' : 'continue-learning'}>
					{!feedback ? (
						<Button onClick={handleSubmit}>Submit</Button>
					) : (
						<Button onClick={closeModal}>Continue Learning</Button>
					)}
				</div>
			</div>
		</div>
	);
};

export default AbstractOrdering;
