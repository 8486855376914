import LearnerActivityDescription from 'components/LearnerActivityDescription';

const AbstractReading = ({ name, description, onComplete }) => {
	onComplete();

	return (
		<>
			<LearnerActivityDescription title={name} text={description} />
		</>
	);
};

export default AbstractReading;
