import { API_ROUTES } from '@constants/api';
import { api, isErrorAPIType } from '@utils/api';
import Button from 'components/Button';
import LearnerActivityDescription from 'components/LearnerActivityDescription';
import DragDrop from 'components/Reinforcements/DragDrop';
import React, { FC, useCallback, useState } from 'react';
import { DragDropLearnerDTO, ReferenceModule } from 'types/DTOs/learnerActivityDTOs';

interface IAbstractDragDrop {
	name: string;
	description: string;
	question: DragDropLearnerDTO;
	referenceModule: ReferenceModule;
	activityId: string;
	completion: string;
	closeModal: () => void;
	readNotification: () => void;
}

const AbstractDragDrop: FC<IAbstractDragDrop> = ({
	name,
	description,
	question,
	referenceModule,
	activityId,
	completion,
	closeModal,
	readNotification,
}) => {
	const [selected, setSelected] = useState<string>(null);
	const [feedback, setFeedback] = useState(null);

	const handleSubmit = useCallback(async () => {
		const payload = {
			activity: activityId,
			completion,
			submittedDD: [
				{
					contentId: question._id,
					answer: selected,
				},
			],
		};
		const res = await api.post({ endpoint: API_ROUTES.dragDropAssignment, payload });
		await readNotification();
		if (!isErrorAPIType(res)) {
			setFeedback(res.data.submittedDD[0]);
		}
	}, [selected]);

	return (
		<div>
			<LearnerActivityDescription title={name} text={description} />

			<DragDrop
				data={question}
				feedback={feedback}
				selected={selected}
				referenceModule={referenceModule}
				setSelected={setSelected}
			/>

			<div className="submit-btns-container">
				<div className={!feedback ? '' : 'continue-learning'}>
					{!feedback ? (
						<Button onClick={handleSubmit}>Submit</Button>
					) : (
						<Button onClick={closeModal}>Continue Learning</Button>
					)}
				</div>
			</div>
		</div>
	);
};

export default AbstractDragDrop;
