import { API_ROUTES } from '@constants/api';
import { useCallback, useReducer, useState } from 'react';
import { ReinforcementActivityType } from 'types/DTOs/activityDTOs';
import { api } from 'utils/api';

type StateType = {
	activityType: ReinforcementActivityType;
	activityTypeObject: any;
	completionParams: any;
};

enum ACTIONS {
	SET_ACTIVITY_TYPE = 'SET_ACTIVITY_TYPE',
	SET_ACTIVITY_TYPE_OBJECT = 'SET_ACTIVITY_TYPE_OBJECT',
	SET_COMPLETION_PARAMS = 'SET_COMPLETION_PARAMS',
	RESET_ALL = 'RESET_ALL',
	UPDATE_COMPLETION_PARAMS = 'UPDATE_COMPLETION_PARAMS',
}

type ActionType =
	| { type: ACTIONS.SET_ACTIVITY_TYPE; payload: StateType['activityType'] }
	| { type: ACTIONS.SET_ACTIVITY_TYPE_OBJECT; payload: StateType['activityTypeObject'] }
	| { type: ACTIONS.SET_COMPLETION_PARAMS; payload: StateType['completionParams'] }
	| { type: ACTIONS.UPDATE_COMPLETION_PARAMS; payload: Partial<StateType['completionParams']> }
	| { type: ACTIONS.RESET_ALL };

const INITIAL_STATE: StateType = {
	activityType: null,
	activityTypeObject: null,
	completionParams: {},
};

export const useLearnerReinforcementActivity = () => {
	const reducer = (state: StateType, action: ActionType): StateType => {
		switch (action.type) {
			case ACTIONS.SET_ACTIVITY_TYPE:
				return {
					...state,
					activityType: action.payload,
				};
			case ACTIONS.SET_ACTIVITY_TYPE_OBJECT:
				return {
					...state,
					activityTypeObject: action.payload,
				};
			case ACTIONS.SET_COMPLETION_PARAMS:
				return {
					...state,
					completionParams: action.payload,
				};
			case ACTIONS.UPDATE_COMPLETION_PARAMS:
				return {
					...state,
					completionParams: {
						...state.completionParams,
						...action.payload,
					},
				};
			case ACTIONS.RESET_ALL: {
				return INITIAL_STATE;
			}
		}
	};

	const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

	const getActivity = useCallback(async (reinforcementID: string, activityID: string, notificationId: string) => {
		if (reinforcementID && activityID) {
			const { activity } = await api.get({
				endpoint: API_ROUTES.learnerReinforcementActivity.expand({ reinforcementID, activityID }),
			});
			if (activity) {
				dispatch({ type: ACTIONS.SET_ACTIVITY_TYPE, payload: activity.type });
				dispatch({ type: ACTIONS.SET_ACTIVITY_TYPE_OBJECT, payload: activity.activityTypeObject });
				dispatch({ type: ACTIONS.SET_COMPLETION_PARAMS, payload: { ...activity.completionParams, notificationId } });
			}
		}
	}, []);

	const updateCompletionParams = useCallback((params) => {
		dispatch({ type: ACTIONS.UPDATE_COMPLETION_PARAMS, payload: params });
	}, []);

	const reset = useCallback(() => {
		dispatch({ type: ACTIONS.RESET_ALL });
	}, []);

	const takeActivityLater = useCallback(
		async (reinforcementID: string, activityID: string, reinforcementName: string) => {
			if (reinforcementID && activityID) {
				await api.post({
					endpoint: API_ROUTES.learnerReinforcementActivityTakeLater.expand({ reinforcementID, activityID }),
					payload: { reinforcementName },
				});
			}
		},
		[]
	);

	return { state, getActivity, updateCompletionParams, reset, takeActivityLater };
};
