import { useState } from 'react';
import axios from 'axios';

import LessonAudioPreview from 'components/Dashboard/Lesson/Audio';
import LessonVideoPreview from 'components/Dashboard/Lesson/Video';
import LessonPresentationPreview from 'components/Dashboard/Lesson/Presentation';

import { Font, DownloadCard } from 'components/Dashboard/Lesson/Styles';
import { Spinner } from 'react-bootstrap';
import { isMobile } from 'react-device-detect';
import { IoMdDownload } from 'react-icons/io';
import Button from 'components/Button';
import PDFPreviewContainer from '../PDFPreview/PDFPreviewContainer';
import AudioPlayer from 'components/Common/Audio/AudioPlayer';

const LessonPreview = (props) => {
	const {
		previewType,
		listFileItem = [],
		fileUrl,
		fileSubtitle,
		setPreview,
		filePathFormAPI,
		setIsDisabled,
		courseLanguage,
	} = props;

	const [loadingSingleDownload, setLoadingSingleDownload] = useState(false);

	const axiosDownloadFile = async (fileUrl, fileName) => {
		try {
			const response = await axios.get(fileUrl, {
				responseType: 'blob', // Important: Specify the response type as 'blob'
			});

			// Create a Blob with the file content
			const blob = new Blob([response.data], { type: response.headers['content-type'] });

			// Create a temporary URL for the Blob
			const blobUrl = URL.createObjectURL(blob);

			// Create an anchor element and simulate a click event to trigger download
			const anchor = document.createElement('a');
			anchor.href = blobUrl;
			anchor.download = fileName || 'downloaded-file.pdf'; // Specify the desired filename
			anchor.click();

			// Clean up the temporary URL
			URL.revokeObjectURL(blobUrl);
		} catch (error) {
			return fileName;
		}
	};

	const handleSingleDownload = async (index, path, file) => {
		setLoadingSingleDownload(index);
		const message = await axiosDownloadFile(path, file);

		setTimeout(() => {
			setLoadingSingleDownload();
			if (message) alert('Error downloading file: ' + message);
		}, 300);
	};

	return (
		<div className="mb-3 mt-3">
			<div className="lms-content-container" style={{ height: 'max-content', minHeight: 'unset' }}>
				{previewType === '1' && (
					<div>
						<div className='desktop-view'>
							<LessonAudioPreview fileUrl={fileUrl} setIsDisabled={setIsDisabled} />
						</div>
						<div className='mobile-view mt-5'>
							<AudioPlayer src={fileUrl} onEnded={() => setIsDisabled(false)} />
						</div>
					</div>
				)}
				{previewType === '2' && (
					<LessonVideoPreview fileUrl={fileUrl} fileSubtitle={fileSubtitle} setIsDisabled={setIsDisabled} />
				)}
				{previewType === '3' && (
					<PDFPreviewContainer filePath={filePathFormAPI}>
						<LessonPresentationPreview fileUrl={fileUrl} />
					</PDFPreviewContainer>
				)}
			</div>

			{listFileItem.length > 0 && (
				<div className="lms-content-container mt-4">
					<Font>File Downloads</Font>
					{listFileItem.map((data, index) => {
						const { name, path } = data;
						return (
							<DownloadCard className="d-flex justify-content-between" key={index}>
								<div
									className="d-flex gap-5 align-items-center cursor-pointer"
									style={{ width: '50%' }}
									onClick={() => setPreview(data)}
								>
									<img draggable={false} src="/global/icon_folder.svg" />
									<Font dir={courseLanguage === 'ar' ? 'rtl' : 'ltr'} size="2rem">
										{name}
									</Font>
								</div>
								{isMobile ? (
									<IoMdDownload
										size={24}
										color={'#066bfb'}
										onClick={() => handleSingleDownload(index, path, name)}
										className="mobile-download-icon"
									/>
								) : (
									<Button
										onClick={() => handleSingleDownload(index, path, name)}
										isLoading={loadingSingleDownload === index}
										type="secondary"
									>
										Download
									</Button>
								)}
							</DownloadCard>
						);
					})}
				</div>
			)}
			{/* TEMPORARY HIDE */}
			{/* {isCommentVisible === "VISIBLE" && <PreviewComment/>} */}
		</div>
	);
};

export default LessonPreview;
