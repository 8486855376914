import { Dispatch, FC, SetStateAction, useCallback } from 'react';
import AbstractPresentation from '../AbstractActivities/AbstractPresentation';
import AbstractAudio from '../AbstractActivities/AbstractAudio';
import AbstractReading from '../AbstractActivities/AbstractReading';
import AbstractQuiz from '../AbstractActivities/AbstractQuiz';
import AbstractVideo from '../AbstractActivities/AbstractVideo';
import AbstractFlipCards from '../AbstractActivities/AbstractFlipCards';
import AbstractLesson from '../AbstractActivities/AbstractLesson';
import AbstractScramble from 'components/AbstractActivities/AbstractScramble';
import AbstractDownloadableFiles from 'components/AbstractActivities/AbstractDownloadableFiles';
import AbstractAssignment from 'components/AbstractActivities/AbstractAssignment';
import AbstractDragDrop from 'components/AbstractActivities/AbstractDragDrop';
import { ReinforcementActivityType } from 'types/DTOs/activityDTOs';
import AbstractOrdering from 'components/AbstractActivities/AbstractOrdering';
import { readNotification } from 'services/api/InAppNotification';

interface IStep2ActivityComponent {
	completionID: string;
	activityType: ReinforcementActivityType;
	activityTypeObject: any;
	canBeCompleted: boolean;
	completionParams?: any;
	setCanBeCompleted: Dispatch<SetStateAction<boolean>>;
	setCompletionParams: Dispatch<SetStateAction<any>>;
	completeActivity?: () => void;
	cancel?: () => void;
	closeModal?: () => void;
}

const Step2ActivityComponent: FC<IStep2ActivityComponent> = ({
	completionID,
	activityType,
	activityTypeObject,
	canBeCompleted,
	completionParams,
	setCanBeCompleted,
	setCompletionParams,
	completeActivity,
	cancel,
	closeModal,
}) => {
	const handleComplete = useCallback(
		(completed = true) => {
			setCanBeCompleted(completed);
		},
		[setCanBeCompleted]
	);

	const readNotificationOnComplete = useCallback(async () => {
		await readNotification({ notifications: [completionParams.notificationId] });
	}, [completionParams?.notificationId]);

	switch (activityType) {
		case 'presentation':
			return (
				<AbstractPresentation
					name={activityTypeObject.name}
					description={activityTypeObject.description}
					fileURL={activityTypeObject.presentationFile}
					onComplete={handleComplete}
				/>
			);
		case 'audio':
			return (
				<AbstractAudio
					name={activityTypeObject.name}
					description={activityTypeObject.description}
					fileURL={activityTypeObject.audioFile}
					onComplete={handleComplete}
				/>
			);
		case 'video':
			return (
				<AbstractVideo
					name={activityTypeObject.name}
					description={activityTypeObject.description}
					fileURL={activityTypeObject.videoFile}
					onComplete={handleComplete}
				/>
			);
		case 'reading':
			return (
				<AbstractReading
					name={activityTypeObject.name}
					description={activityTypeObject.description}
					onComplete={handleComplete}
				/>
			);
		case 'flip_card':
			return (
				<AbstractFlipCards
					name={activityTypeObject.name}
					description={activityTypeObject.description}
					cards={activityTypeObject.cards}
					onComplete={handleComplete}
					setCompletionParams={setCompletionParams}
				/>
			);
		case 'lesson':
			return (
				<AbstractLesson
					name={activityTypeObject.name}
					description={activityTypeObject.description}
					type={activityTypeObject.type}
					file={activityTypeObject.file}
					downloadFiles={activityTypeObject.downloadFiles}
					subtitleFile={activityTypeObject.subtitleFile}
					url={activityTypeObject.url}
					onComplete={handleComplete}
				/>
			);
		case 'download_file':
			return (
				<AbstractDownloadableFiles
					name={activityTypeObject.name}
					description={activityTypeObject.description}
					files={activityTypeObject.files}
					onComplete={handleComplete}
				/>
			);
		case 'quiz':
			return (
				<AbstractQuiz
					quizID={activityTypeObject.quizID}
					questions={activityTypeObject.questions}
					completionID={completionID}
					name={activityTypeObject.name}
					description={activityTypeObject.description}
					canBeCompleted={canBeCompleted}
					referenceModule={activityTypeObject.referenceModule}
					cancel={cancel}
					closeModal={closeModal}
					readNotification={readNotificationOnComplete}
				/>
			);
		case 'scramble_sentences':
			return (
				<AbstractScramble
					name={activityTypeObject.name}
					description={activityTypeObject.description}
					sentence={activityTypeObject.sentences[0]}
					completionID={completionParams.completion}
					referenceModule={activityTypeObject.referenceModule}
					canBeCompleted={canBeCompleted}
					closeModal={closeModal}
					readNotification={readNotificationOnComplete}
				/>
			);
		case 'assignment':
			return (
				<AbstractAssignment
					assignmentId={activityTypeObject.assignmentId}
					completion={completionParams.completion}
					name={activityTypeObject.name}
					description={activityTypeObject.description}
					file={activityTypeObject.file}
					canBeCompleted={canBeCompleted}
					completeActivity={completeActivity}
					cancel={cancel}
					readNotification={readNotificationOnComplete}
				/>
			);
		case 'drag_drop':
			return (
				<AbstractDragDrop
					activityId={activityTypeObject.dragDropId}
					completion={completionParams.completion}
					name={activityTypeObject.name}
					description={activityTypeObject.description}
					referenceModule={activityTypeObject.referenceModule}
					question={activityTypeObject.question}
					closeModal={closeModal}
					readNotification={readNotificationOnComplete}
				/>
			);
		case 'ordering':
			return (
				<AbstractOrdering
					activityId={activityTypeObject.reorderingId}
					completion={completionParams.completion}
					name={activityTypeObject.name}
					description={activityTypeObject.description}
					items={activityTypeObject.contents}
					referenceModule={activityTypeObject.referenceModule}
					closeModal={closeModal}
					readNotification={readNotificationOnComplete}
				/>
			);
	}

	return <></>;
};

export default Step2ActivityComponent;
