import LearnerActivityDescription from 'components/LearnerActivityDescription';
import ReactAudioPlayer from 'react-audio-player';

const AbstractAudio = ({ name, description, fileURL, onComplete }) => {
	return (
		<div className="audio">
			<LearnerActivityDescription title={name} text={description} />
			<div className="audio-container">
				<img src="/library/music_soundbar.png" width="50%" />
				<ReactAudioPlayer onEnded={() => onComplete()} src={fileURL} controls style={{ width: '100%' }} />
			</div>
		</div>
	);
};

export default AbstractAudio;
