import React from 'react';
import Spinner from 'react-bootstrap/Spinner';

const AbsoluteSpinner = () => {
	return (
		<div className="loading absolute-loading">
			<Spinner animation="border" />
		</div>
	);
};

export default AbsoluteSpinner;
