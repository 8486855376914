import DownloadCardComponent from './DownloadCardComponent';
import React, { useCallback, useEffect, useState } from 'react';

const DownloadableFilesComponent = ({ files, onComplete }) => {
	const [downloadedFiles, setDownloadedFiles] = useState<string[]>([]);

	const handleFileComplete = useCallback(
		(path: string) => setDownloadedFiles(prevArray => [...prevArray, path]),
		[files]
	);

  useEffect(() => {
    const allFilesAreDownloaded = files.map(file => file.path).every(element => downloadedFiles.includes(element));
    if (allFilesAreDownloaded) {
      onComplete();
    }
  }, [downloadedFiles]);

	if (!files?.length) return null;

	return (
		<div>
			{files?.map((file, index) => (
				<DownloadCardComponent
					key={index}
					fileName={file.name ?? file.file}
					path={file.path}
					onComplete={handleFileComplete}
				/>
			))}
		</div>
	);
};

export default DownloadableFilesComponent;
