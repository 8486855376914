import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { Spinner } from 'react-bootstrap';

// Component
import NotificationItem from './NotificationItem';

// utils
import generateTotalNotification from 'utils/calculate_notifications';
import { clearNotification, readNotification, unreadNotification } from 'services/api/InAppNotification';
import ReinforcementActivityNotificationItem from './ReinforcementActivityNotificationItem';

const NotificationWrapper = ({ list, total, getList, isOpen, closeNotificationPanel }) => {
	const [currentType, setCurrentType] = useState('');

	const handleReadStatus = async ({ id, read }) => {
		let response;

		if (read) {
			response = await readNotification({ notifications: [id] });
		} else {
			response = await unreadNotification({ id });
		}
	};

	const handleClearNotifications = async () => {
		const response = await clearNotification();

		if (!response.success) {
			alert(response.errors || 'Something Error');
		} else {
			await getList({});
		}
	};

	const handleFilterUnred = () => {
		setCurrentType('unread');
		getList({ type: 'unread' });
	};

	const allUnreadReinforcements = useMemo(
		() =>
			list
				?.filter((notification) => notification?.extra?.reinforcement && !notification?.isRead)
				?.map((data) => ({
					...data?.extra,
					reinforcement: {
						...data?.extra?.reinforcement,
						notificationId: data?._id,
					},
				})),
		[list]
	);

	return (
		<div className={`notification__wrapper ${isOpen ? 'd-inline-block' : 'd-none'}`}>
			<div className="notification__header">
				<Font>
					{generateTotalNotification(total)} Notification{total > 1 ? 's' : ''}
				</Font>

				{total ? (
					<div className="filter align-items-center w-auto">
						<div className="sort-wrapper w-auto h-fit" style={{ height: 'max-content' }}>
							<div className="sort p-0" style={{ height: 0 }}>
								<img role="button" src="/user-management/dot.svg" />
							</div>
							<div
								className="sort-dropdown mt-0 p-3 end-0 text-dark"
								style={{
									top: 10,
									left: 'unset',
									width: '30rem',
								}}
							>
								<div className="sort-dropdown__item m-0 p-3" onClick={handleFilterUnred}>
									Show only unread message
								</div>
								<div className="sort-dropdown__item m-0 p-3" onClick={handleClearNotifications}>
									Clear all notifications
								</div>
							</div>
						</div>
					</div>
				) : null}
			</div>

			{total ? (
				<>
					<div className="d-flex flex-column">
						{list.map((data) => {
							let component = <></>;
							switch (data.code) {
								case 'reinforcement_activity':
									component = (
										<ReinforcementActivityNotificationItem
											key={data._id}
											{...data}
											allUnreadReinforcements={allUnreadReinforcements}
											onChangeStatus={handleReadStatus}
											closeNotificationPanel={closeNotificationPanel}
										/>
									);
									break;
								default:
									component = <NotificationItem key={data._id} {...data} onChangeStatus={handleReadStatus} />;
									break;
							}
							return component;
						})}
					</div>
				</>
			) : null}

			<Font weight="400" size="1.6rem" className="notification__footer">
				{total
					? `Displaying all ${!currentType ? '' : 'unread'} notifications for the last 30 days`
					: `You don't have any ${!currentType ? '' : 'unread'} notification`}
			</Font>
		</div>
	);
};

export default NotificationWrapper;

export const Font = styled.div`
	font-family: 'Titillium Web';
	font-size: ${({ size }) => size || '1.8rem'};
	font-weight: ${({ weight }) => weight || 600};
	color: ${({ color }) => color || 'black'};
	background-color: transparent !important;
`;
