import ModalScrollContextProvider from '@contexts/modal-scroll-context';
import { FC, useEffect, useMemo } from 'react';
import Modal from 'react-bootstrap/Modal';
import { ISimpleModal } from 'types/interfaces/modalInterfaces';
import AbsoluteSpinner from './AbsoluteSpinner';

const SimpleModal: FC<ISimpleModal> = ({ show, handleClose, title = '', children, loading = false }) => {
	const modal = useMemo(() => document.getElementsByClassName('simple-modal')?.[0], []);

	useEffect(() => {
		modal?.scrollTo({ top: 0, behavior: 'smooth' });
	}, [children]);

	return (
		<ModalScrollContextProvider modal={modal}>
			<Modal show={show} onHide={handleClose} className="simple-modal" size="lg" centered>
				<>
					{loading && <AbsoluteSpinner />}
					<Modal.Header closeButton>
						<Modal.Title className="title">{title}</Modal.Title>
						<p className="btn-modal-close" onClick={handleClose}>
							<i className="fa fa-times text-danger"></i>
						</p>
					</Modal.Header>
					<Modal.Body>{children}</Modal.Body>
				</>
			</Modal>
		</ModalScrollContextProvider>
	);
};

export default SimpleModal;
