import React, { FC } from 'react';

interface IUploadVideo {
	file: {
		name: string;
		path: string;
	};
	url?: string;
	withURL?: boolean;
	setFile: (file: any) => void;
	setURL?: (url: string) => void;
}

const UploadVideo: FC<IUploadVideo> = ({ file, url, setFile, setURL, withURL = true }) => {
	const checkExtension = (name) => {
		let data = name.split('.');
		const allow = { mp4: 'mp4', mov: 'mov' };
		data = data[data.length - 1];
		if (allow[data]) return false;
		return true;
	};

	const handleFileChange = (e) => {
		if (e) {
			let file = e.target.files[0];

			if (!file.type.includes('video/')) {
				alert('Must be an video file!');
				return;
			}

			if (checkExtension(file.name)) {
				alert('Must be an mp4, mov file!');
				return;
			}

			if (file.size > 548057600) {
				alert('File is too big');
			} else {
				setFile(file);
			}
		}
	};

	return (
		<div>
			<div className={url ? 'disabled-input' : ''}>
				<div className="discussions-create-form-file">
					<input
						type="file"
						onChange={handleFileChange}
						// onClick={(e) => {
						// 	e.target.value = null;
						// }}
						accept="video/*"
					/>
					<div className="discussions-create-form-file-content">
						<img src="/discussions/upload.svg" alt="Upload" />
						<span className="discussions-create-form-file-text">
							Drop files to attach, or <span className="discussions-create-form-file-text-link">Browse</span>
							<br />( Max file size: 500MB )
						</span>
					</div>
				</div>
			</div>
			{file ? (
				<div className="mt-4 gap-3 downloadable-card">
					<img src="/discussions/file.svg" alt="Doc Icon" draggable={false} />
					<div className="file-status d-flex flex-column gap-2">
						<div className="title">{file.name}</div>
					</div>
					<img
						onClick={() => setFile(null)}
						style={{ marginLeft: 'auto' }}
						role="button"
						src="/discussions/trash.svg"
						draggable={false}
					/>
				</div>
			) : null}
			{withURL ? (
				<div>
					<div className="text-center" style={{ fontSize: '1.6rem', margin: '24px 0', fontFamily: 'Titillium Web' }}>
						Or
					</div>
					<div className="">
						<label htmlFor="topic">Video URL</label>
						<input
							id="topic"
							className={`mt-3 discussions-create-form-input ${file ? 'disabled-input' : ''}`}
							type="text"
							placeholder="https://"
							value={url}
							disabled={!!file}
							onChange={(e) => setURL(e.target.value)}
						/>
					</div>
				</div>
			) : null}
		</div>
	);
};

export default UploadVideo;
