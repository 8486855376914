import React, { FC } from 'react';
import { Spinner } from 'react-bootstrap';

interface IButton {
	isLoading?: boolean;
	disabled?: boolean;
	type?: 'primary' | 'secondary';
	style?: React.CSSProperties;
	className?: string;
	onClick: () => void;
	children: React.ReactNode;
}

const Button: FC<IButton> = ({
	isLoading = false,
	disabled = false,
	type = 'primary',
	style = {},
	className = '',
	children,
	onClick,
}) => {
	return (
		<button
			className={`button ${className} ${type === 'primary' ? 'button-outline' : ''} ${
				disabled ? 'button-disabled' : ''
			}`}
			onClick={onClick}
			disabled={isLoading || disabled}
			style={{ ...style, width: 'auto', padding: '16px 32px' }}
		>
			{isLoading && <Spinner size="sm" animation="border" style={{ marginRight: 4, marginBottom: 2 }} />} {children}
		</button>
	);
};

export default Button;
