import styled from "styled-components"

import { ProgressBar as Bar } from "react-bootstrap";


export const ProgressBar = styled(Bar)`
  width: 34.8rem;
  height: 1rem;
  @media (max-width: 56.25em) {
    width: 100%;
  }
`

export const Font = styled.div`
  font-family: "Titillium Web";
  font-size: ${({ size }) => size || "2rem"};
  font-weight: ${({ weight }) => weight || 600};
  color: ${({ color }) => color || "black"};
  line-height: ${({ height }) => height || "3rem"};
`;

export const Span = styled.span`
  font-family: "Titillium Web";
  font-size: ${({ size }) => size || "2rem"};
  font-weight: ${({ weight }) => weight || 600};
  color: ${({ color }) => color || "black"};
  line-height: ${({ height }) => height || "3rem"};
`;

export const Label = styled.label`
  font-family: "Titillium Web";
  font-size: ${({ size }) => `${size} !important` || "2rem"};
  font-weight: ${({ weight }) => weight || 600};
  color: ${({ color }) => color || "black"};
  line-height: ${({ height }) => height || "3rem"};
`;

export const DiscussionContainer = styled.div`
  @media (max-width: 56.25em) {
    padding: unset;
  }
`

export const FileAttachmentWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 20rem;
  grid-gap: 2rem;
  margin-top: 5rem;
  margin-bottom: 5rem;
  align-items: center;
  @media (max-width: 37.5em) {
    grid-template-columns: 1fr;
  }
`

export const Card = styled.div`
  background-image: url(${({ src }) => src});
  background-repeat: no-repeat;
  background-color: ${({ color }) => color || "white"};
  background-position: center;
  background-size: ${({ type }) => type === "center" ? "32rem 22.6rem" : "cover"};
  display:grid;
  
  padding: 1.6rem 1.6rem 2.4rem;
  box-shadow: 0px 8px 12px rgba(173, 173, 173, 0.24);
  height: 42.4rem;
  border-radius: 12px;
`

export const ScrambleCard = styled.div`
  padding: 1.6rem;
  background: #FFFFFF;
  box-shadow: 0px 8px 12px rgba(173, 173, 173, 0.24);
  border-radius: 12px;

  margin-top: 2.4rem;
`