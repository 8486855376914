
{/* STRUCTURE : 
  { 
    type: "",
    frontValue: "",
    backValue: "",
    selectBack: {},
    selectFront: {},
  }
*/}
import { useState, useEffect } from "react";

const opt = { label: "Text", value:"text" }
const defaultSelect = {
  selectBack: opt,
  selectFront: opt
}

const useFlipCardField = (isEdit) => {

  const [listData, setListData] = useState({});

  // List to submit into Learner API
  const [listToSubmit, setListToSubmit] = useState([])
  const [deletedKey, setDeletedKey] = useState([])

  const [flippedCard, setFlippedCard] = useState({});

  // Init First 3 Cards
  useEffect(() => {

    const init = () => {
      const newData = { ...listData } 

      // Before it was 3 as a default, now its 1
      for (let x = 0; x < 1; x++) {
        newData[x] = { ...defaultSelect, type:"frontValue", frontValue:"", backValue:"" }
      }

      setListData(newData)

    }

    // Produce 3 cards for first load
    if (!isEdit) init();

  }, [isEdit])

  const checkIsEmpty = () => {
    let result = false;

    for (const key in listData) {
      const field = listData[key]

      // If front value or backValue is empty, return true
      if (!field.frontValue || !field.backValue) {
        result = true
        break;
      }
    }
    return result
  }

  // Flip the card
  const onFlip = (key, type) => {
    const newData = { ...listData }
    const newFlipped = { ...flippedCard }

    newFlipped[key] = true
    newData[key]["type"] = type

    setListData(newData)
    setFlippedCard(newFlipped)
  }

  // Check is all card flipped 
  const checkIsAllEverFlipped = () => {
    let result = true
    for(const key in flippedCard) {
      if (flippedCard[key] === false) {
        result = false
        break
      }
    }
    return result
  }

  // Chcange card
  const onChange = (key, type, value, fileUrl="") => {
    const newData = { ...listData }

    // When changing the select dropdown, remove the card value so that the placeholder is shown
    if(typeof value === "object") {

      if (type === "selectFront") {
        newData[key]["frontValue"] = ""
        newData[key]["urlfrontValue"] = ""
      }

      if (type === "selectBack") {
        newData[key]["backValue"] = ""
        newData[key]["urlbackValue"] = ""
      }

    }

    // Convert into local url when card value is file
    if (fileUrl) newData[key]["url"+type] = URL.createObjectURL(value)

    newData[key][type] = value
    setListData(newData)
  }

  // Delete card
  const onDelete = (key) => {
    const newData = { ...listData }
    const newKey = [...deletedKey]

    newKey.push(key)
    delete newData[key]

    setDeletedKey(newKey)
    setListData(newData)
  }

  // Init data when edit
  const initCard = (data = []) => {
    // data.reverse()

    const newData = {}
    const flippedCard = {}
    const listToSubmit = []
    const dropdownType = {
      "1" : { label:"Text", value:"text" },
      "2" : { label:"Centered Image", value:"center" },
      "3" : { label:"Full card image", value:"full" }
    }

    for(let x = 1; x < data.length; x += 2) {

      const { content:c1, cardId, cardTypeId:type1 } = data[x - 1]
      const { content:c2, cardId:cardId2, cardTypeId:type2 } = data[x]

      // Setup list flipped card
      flippedCard[cardId] = false

      // List to submit into learner API
      listToSubmit.push(
        {cardId, isFlipped:true},
        {cardId:cardId2, isFlipped:true}
      )

      // Setup Card Content
      newData[cardId] = {
        type:"frontValue",
        ids: { cardId, cardId2 },
        frontValue: c1,
        urlfrontValue: c1,
        backValue: c2,
        urlbackValue: c2,
        selectFront: {...dropdownType[type1] },
        selectBack:  {...dropdownType[type2] }
      }
  
    }
    setListToSubmit(listToSubmit)
    setFlippedCard(flippedCard);
    setListData(newData)
  }

  // Add new card
  const addCard = (key) => {
    let newKey = new Date() + Math.random();
    const newData = { ...listData } 
    newData[newKey] = { ...defaultSelect, type:"frontValue", frontValue:"", backValue:"" }
    setListData(newData)
  }

  // Process data to be shown as react component
  const showCards = () => {
    const result = []
    for (const key in listData) {
      result.push({
        ...listData[key],
        key,
      })
    }
    return result;
  }

  // Process data to submit to the API ( for preview admin API )
  const payloadToSend = () => {
    let result = []
    let count = 0;
    let typeId = { text:"1", center:"2", full:"3" }

    for (const key in listData) {
      const value = listData[key]

      // Data for front card
      const formData1 = new FormData();
      formData1.append("sequence", ++count);
      formData1.append("cardSection", 1);
      formData1.append("cardTypeId", typeId[value.selectFront.value])
      formData1.append("content", value.frontValue);
      if (typeof value.frontValue === "object" && isEdit) formData1.append("file", value.frontValue)
      result.push({ value:formData1, key:count, cardId: value?.ids?.cardId || "" })

      // Data for back card
      const formData2 = new FormData();
      formData2.append("sequence", ++count);
      formData2.append("cardSection", 2);
      formData2.append("cardTypeId", typeId[value.selectBack.value])
      formData2.append("content", value.backValue);
      if (typeof value.backValue === "object" && isEdit) formData2.append("file", value.backValue)
      result.push({ value:formData2, key:count, cardId: value?.ids?.cardId2 || "" })
      
    }

    return result
  }

  return {
    onChange,
    onDelete,
    addCard,
    onFlip,
    payloadToSend,
    payloadToSend2:listToSubmit,
    initCard,
    listCard: showCards(),
    deletedKey,
    checkIsEmpty: checkIsEmpty(),
    checkIsAllEverFlipped
  }
}

export default useFlipCardField;