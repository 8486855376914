import React from 'react';
import FeedbackMarkIcon from './FeedbackMarkIcon';

const FeedbackMark = ({ anyFeedback, individualFeedback }) =>
	individualFeedback ? (
		<div>
			<FeedbackMarkIcon type={individualFeedback.isCorrectAnswer ? 'correct' : 'wrong'} />
		</div>
	) : anyFeedback ? (
		<div className="feedback-placeholder" />
	) : null;

export default FeedbackMark;
