import { DragEndEvent } from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';
import _ from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import { EditOrderedItemType, OrderedItemType } from 'types/activities';

export const useOrderedFields = (setFields = (fields: Array<EditOrderedItemType>) => {}) => {
	const [items, setItems] = useState<Array<OrderedItemType>>([]);

	const initializeFields = useCallback((fields: Array<EditOrderedItemType>) => {
		if (fields) {
			const sortedItems = fields
				.toSorted((a, b) => a.sequence - b.sequence)
				.map((item) => ({ ...item, id: item?._id ?? Math.random().toString() }));
			setItems(sortedItems);
			if (setFields) {
				setFields(sortedItems);
			}
		}
	}, []);

	const handleDragEnd = useCallback((event: DragEndEvent) => {
		const { active, over } = event;

		if (active.id !== over.id) {
			setItems((items) => {
				const oldIndex = items.findIndex((item) => item.id === active.id);
				const newIndex = items.findIndex((item) => item.id === over.id);
				const newArray = arrayMove(items, oldIndex, newIndex).map((item, index) => ({ ...item, sequence: index + 1 }));
				if (setFields) {
					const arrayWithoutIds: Array<EditOrderedItemType> = newArray.map((item) => {
						let newItem = { ...item };
						delete newItem['id'];
						return newItem;
					});
					setFields(arrayWithoutIds);
				}
				return newArray;
			});
		}
	}, []);

	const updateFields = useCallback(
		(fields: Array<EditOrderedItemType>) => {
			if (fields.length < items.length) {
				const newSequences = fields.map((item) => item.sequence);
				const removedSequence = items.find((item) => !newSequences.includes(item.sequence))?.sequence;
				const newItems = items.filter((item) => item.sequence !== removedSequence);
				setItems(newItems);
			}
			if (fields.length > items.length) {
				let temp = [...items];
				let newFields = fields.slice(items.length);
				let newItems = newFields.map((item) => ({ ...item, id: Math.random().toString() }));
				setItems(temp.concat(newItems));
			} else {
				setItems(fields.map((newItem, index) => ({ ...items[index], ...newItem })));
			}
		},
		[items]
	);

	return {
		fields: items,
		initializeFields,
		updateFields,
		handleDragEnd,
	};
};

export const useEditOrderedFields = () => {
	const [items, setItems] = useState<Array<EditOrderedItemType>>([]);

	const initializeFields = useCallback((fields: Array<EditOrderedItemType>) => {
		if (fields) {
			setItems(fields.toSorted((a, b) => a.sequence - b.sequence));
		}
	}, []);

	const addField = useCallback(() => {
		const newFields = [...items];
		newFields.push({
			content: '',
			sequence: newFields.length + 1,
		});
		setItems(newFields);
	}, [items]);

	const updateField = useCallback(
		(index: number, value: string) => {
			const tempItems = [...items];
			tempItems[index].content = value;
			setItems(tempItems);
		},
		[items]
	);

	const removeField = useCallback(
		(index: number) => {
			setItems((items) => items.filter((_, i) => i !== index));
		},
		[items]
	);

	const areNotCompleted = useMemo(() => items.some((item) => !item.content.length), [items]);

	return {
		fields: items,
		initializeFields,
		addField,
		updateField,
		removeField,
		areNotCompleted,
	};
};
