import React, { FC } from 'react';

interface ILearnerActivityDescription {
	title: string;
	text: string;
}

const LearnerActivityDescription: FC<ILearnerActivityDescription> = ({ title, text }) => {
	return (
		<div className="activity-description-container">
			<p className="title">{title}</p>
			<div className="activity-html-description" dangerouslySetInnerHTML={{ __html: text }}></div>
		</div>
	);
};

export default LearnerActivityDescription;
