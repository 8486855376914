import VideoJS from 'utils/video';
import ReactPlayer from 'react-player';

import { useRef } from 'react';

const PLAYER_CONFIG = {
	youtube: {
		playerVars: {
			loop: 0,
		},
	},
};

const VideoPreview = (props) => {
	const { videoFile = '', setIsDisabled, subtitle } = props;

	let videoSrc = videoFile;
	if (videoFile && videoFile.path) {
		videoSrc = videoFile.path;
	} else {
		if (videoFile && typeof videoFile !== 'string') videoSrc = URL.createObjectURL(videoFile);
	}

	const playerRef = useRef(null);

	const videoJsOptions = {
		autoplay: false,
		controls: true,
		responsive: true,
		controlBar: {
			remainingTimeDisplay: true,
			progressControl: {
				seekBar: true,
			},
		},
		playbackRates: [0.5, 1, 1.5, 2],
		fluid: true,
		sources: [
			{
				src: videoSrc,
				type: 'video/mp4',
			},
		],
		tracks: subtitle
			? [
					{
						src: subtitle,
						kind: '',
						srclang: '',
						label: 'Subtitle',
						default: true,
					},
			  ]
			: '',
	};

	const handlePlayerReady = (player) => {
		playerRef.current = player;

		// You can handle player events here, for example:
		player.on('waiting', () => {
			videojs.log('player is waiting');
		});

		player.on('dispose', () => {
			videojs.log('player will dispose');
		});

		player.on('ended', () => {
			setIsDisabled(false);
		});
	};

	if (videoSrc.includes('youtu')) {
		return (
			<div>
				<div className="desktop-view">
					<ReactPlayer
						url={videoSrc}
						onEnded={() => setIsDisabled(false)}
						controls={true}
						width="100%"
						height="80vh"
						config={PLAYER_CONFIG}
					/>
				</div>
				<div className="mobile-view">
					<ReactPlayer
						url={videoSrc}
						onEnded={() => setIsDisabled(false)}
						controls={true}
						width="100%"
						config={PLAYER_CONFIG}
					/>
				</div>
			</div>
		);
	} else {
		return <VideoJS options={videoJsOptions} onReady={handlePlayerReady} />;
	}
};

export default VideoPreview;
