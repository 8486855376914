import { useCallback, useState } from 'react';
import { readNotification } from 'services/api/InAppNotification';
import { ReinforcementActivityType } from 'types/DTOs/activityDTOs';
import { lereroFetch, lereroFetchFile } from 'utils/fetch';

export const useCompleteLearnerActivity = (activityType: ReinforcementActivityType, completionParams: any) => {
	const [success, setSuccess] = useState(false);

	const completeActivity = useCallback(async () => {
		if (activityType === 'reading') {
			const formData = new FormData();

			for (let key in completionParams) {
				formData.append(key, completionParams[key]);
			}

			await lereroFetchFile('POST', 'learner/activity/reading', formData);
		} else if (activityType === 'audio') {
			await lereroFetch('POST', 'learner/activity/audios', completionParams);
		} else if (activityType === 'presentation') {
			await lereroFetch('POST', 'learner/activity/presentations', completionParams);
		} else if (activityType === 'video') {
			await lereroFetch('POST', 'learner/activity/videos', completionParams);
		} else if (activityType === 'flip_card') {
			await lereroFetch('POST', 'learner/activity/flip-cards', completionParams);
		} else if (activityType === 'download_file') {
			await lereroFetch('POST', 'learner/activity/download-files', completionParams);
		} else if (activityType === 'lesson') {
			await lereroFetch('POST', 'learner/activity/lessons', completionParams);
		}
		await readNotification({ notifications: [completionParams.notificationId] });

		setSuccess(true);
	}, [activityType, completionParams]);

	const reset = useCallback(() => setSuccess(false), []);

	return { success, completeActivity, reset };
};
