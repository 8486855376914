import { API_ROUTES } from '@constants/api';
import Button from 'components/Button';
import LearnerActivityDescription from 'components/LearnerActivityDescription';
import ScrambleSentence from 'components/Reinforcements/ScrambleSentence';
import React, { useCallback, useMemo, useState } from 'react';
import { api, isErrorAPIType } from 'utils/api';

const AbstractScramble = ({
	name,
	description,
	sentence,
	completionID,
	referenceModule,
	canBeCompleted,
	closeModal,
	readNotification,
}) => {
	const [userValue, setUserValue] = useState('');
	const [feedback, setFeedback] = useState(null);

	const handleInput = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
		setUserValue(e.target.value);
	}, []);

	const handleSubmit = useCallback(async () => {
		const payload = {
			scrambleSentenceId: sentence.scrambleSentenceId,
			completion: completionID,
			submittedScramble: [
				{
					answer: userValue,
					sentenceId: sentence._id,
				},
			],
		};
		const res = await api.post({ endpoint: API_ROUTES.scrambleSubmit, payload });
		await readNotification();
		if (!isErrorAPIType(res)) {
			setFeedback(res?.data?.submittedScramble?.[0]);
		}
	}, [userValue]);

	const markType = useMemo(() => {
		if (feedback) {
			if (feedback.isCorrectAnswer) {
				return 'correct';
			}
			return 'wrong';
		}
	}, [feedback]);

	return (
		<div className="scramble">
			<LearnerActivityDescription title={name} text={description} />

			<ScrambleSentence
				statement={sentence.statement}
				feedback={feedback}
				value={userValue}
				referenceModule={referenceModule}
				handleInput={handleInput}
			/>

			{!canBeCompleted ? (
				<div className="submit-btns-container">
					<div className={!feedback ? '' : 'continue-learning'}>
						{!feedback ? (
							<Button onClick={handleSubmit}>Submit</Button>
						) : (
							<Button onClick={closeModal}>Continue Learning</Button>
						)}
					</div>
				</div>
			) : null}
		</div>
	);
};

export default AbstractScramble;
