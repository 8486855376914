import { useRouter } from "next/router"
import { useCallback } from "react";

export const useNavigation = () => {
    const router = useRouter();

    const navigate = useCallback((route: string, queryParams?: Record<string, string | string[] | number>) => {
        router.push({
            pathname: route,
            query: queryParams ?? {},
        })
    }, [])

    return navigate;
}