import { useEffect } from 'react';
import useFlipCardField from '../../services/CourseManagement/FlipCards';
import FlipCard from '../Dashboard/FlipCard';
import LearnerActivityDescription from 'components/LearnerActivityDescription';

const AbstractFlipCards = ({ name, description, cards, onComplete, setCompletionParams }) => {
	const useField = useFlipCardField();
	const { payloadToSend2, initCard, checkIsAllEverFlipped } = useField;
	const allFlipCardsFlipped = checkIsAllEverFlipped();

	useEffect(() => {
		initCard(cards);
	}, []);

	useEffect(() => {
		onComplete();
		setCompletionParams({
			submittedCard: payloadToSend2.reverse(),
		});
	}, [allFlipCardsFlipped]);

	return (
		<div className="flip-cards-abstract">
			<LearnerActivityDescription title={name} text={description} />
			<div className="flip-cards">
				<FlipCard fields={useField} onLearner={true} />
			</div>
		</div>
	);
};

export default AbstractFlipCards;
