import LearnerActivityDescription from 'components/LearnerActivityDescription';
import dynamic from 'next/dynamic';

const DocViewer = dynamic(() => import('components/Dashboard/DocViewer'), {
	ssr: false,
});

const AbstractPresentation = ({ name, description, fileURL, onComplete }) => {
	//Maybe add a condition here
	onComplete();

	return (
		<>
			<LearnerActivityDescription title={name} text={description} />
			<div style={{ marginTop: '9rem', marginBottom: '4rem' }}>
				<DocViewer fileUrl={fileURL} />
			</div>
		</>
	);
};

export default AbstractPresentation;
