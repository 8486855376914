import React, { useCallback, useState } from 'react';
import { api } from 'utils/api';
import Button from '../Button';
import { isMobile } from 'react-device-detect';
import { IoMdDownload } from 'react-icons/io';
import { FaFileAlt } from 'react-icons/fa';

const DownloadCardComponent = ({ fileName, path, onComplete = (path: string) => {} }) => {
	const [loading, setLoading] = useState(false);

	const handleSingleDownload = useCallback(async () => {
		setLoading(true);
		try {
			await api.download({ endpoint: path, fileName });
			onComplete(path);
		} catch (err) {
			alert('Error downloading file: ' + err);
		} finally {
			setTimeout(() => {
				setLoading(false);
			}, 300);
		}
	}, [fileName, path]);

	return (
		<div className="downloadable-card">
			<div className="name-container">
				<FaFileAlt size={24} color={'#066bfb'} />
				<p>{fileName}</p>
			</div>
			{isMobile ? (
				<IoMdDownload size={24} color={'#066bfb'} onClick={handleSingleDownload} className="mobile-download-icon" />
			) : (
				<Button onClick={handleSingleDownload} isLoading={loading} type="secondary">
					Download
				</Button>
			)}
		</div>
	);
};

export default DownloadCardComponent;
