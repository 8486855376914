import LessonPreview from '../Dashboard/Lesson/Preview';
import LearnerActivityDescription from 'components/LearnerActivityDescription';

const TYPES = {
	AUDIO: 1,
	VIDEO: 2,
	PRESENTATION: 3,
};

const AbstractLesson = ({ name, description, type, file, downloadFiles, subtitleFile, url, onComplete }) => {

	if (type === TYPES.PRESENTATION) {
		//For presentation type trigger onComplete by default because you do not need to watch something
		onComplete();
	}

	return (
		<>
			<LearnerActivityDescription title={name} text={description} />

			<LessonPreview
				previewType={String(type)}
				fileUrl={file?.path || url || ''}
				fileSubtitle={subtitleFile ? subtitleFile.path : ''}
				listFileItem={downloadFiles}
				isCommentVisible={false}
				setPreview={()=>{}}
				setIsDisabled={() => {
					onComplete();
				}}
				courseLanguage={'en'}
			/>
		</>
	);
};

export default AbstractLesson;
