import React, { useRef, useState } from 'react';
import ReactAudioPlayer from 'react-audio-player';
import { RiPlayCircleLine, RiPauseCircleLine, RiReplay30Line, RiForward30Line } from 'react-icons/ri';

const listSpeed = [
	{
		id: 0,
		speed: 0.25,
		label: '0.25x',
	},
	{
		id: 1,
		speed: 0.5,
		label: '0.5x',
	},
	{
		id: 2,
		speed: 0.75,
		label: '0.75x',
	},
	{
		id: 3,
		speed: 1,
		label: '1x',
	},
	{
		id: 4,
		speed: 1.25,
		label: '1.25x',
	},
	{
		id: 5,
		speed: 1.5,
		label: '1.5x',
	},
	{
		id: 6,
		speed: 1.75,
		label: '1.75x',
	},
	{
		id: 7,
		speed: 2,
		label: '2x',
	},
];

function AudioPlayer({ 
	src = '', 
	onEnded = () => {}, 
	onPlay = () => {}, 
	onPause = () => {}, 
	showName = false, 
	name = '' 
}) {
	
	const audioRef = useRef(null);
	const [isPlaying, setIsPlaying] = useState(false);
	const [speed, setSpeed] = useState(listSpeed[3]);

	const getFileNameFromUrl = (url) => url.split('/').pop();

	const togglePlayPause = () => {
		setIsPlaying((prevIsPlaying) => {
			if (audioRef.current && audioRef.current.audioEl.current) {
				const audioElement = audioRef.current.audioEl.current;
				prevIsPlaying ? audioElement.pause() : audioElement.play();
				return !prevIsPlaying;
			}
			return prevIsPlaying;
		});
	};

	const adjustTime = (seconds) => {
		if (audioRef.current && audioRef.current.audioEl.current) {
			audioRef.current.audioEl.current.currentTime += seconds;
		}
	};

	const handleOnPlay = () => {
		setIsPlaying(true);
		onPlay();
	};

	const handleOnPause = () => {
		setIsPlaying(false);
		onPause();
	};

	const handleOnEnded = () => {
		setIsPlaying(false);
		onEnded();
	};

	const handleChangeSpeed = (item) => {
		if (audioRef.current && audioRef.current.audioEl.current) {
			setSpeed(item);
			audioRef.current.audioEl.current.playbackRate = item.speed;
		}
	};

	return (
		<div className="audio-container">
			{showName && (
				<div className='audio-name text-truncate col-11'>
					{name ? name : getFileNameFromUrl(src)}
				</div>
			)}
			<div className="main-control">
				<div className="prev30" onClick={() => adjustTime(-30)}>
					<RiReplay30Line size={30} />
				</div>
				<div className="play-button" onClick={togglePlayPause}>
					{isPlaying ? <RiPauseCircleLine size={60} /> : <RiPlayCircleLine size={60} />}
				</div>
				<div className="next30" onClick={() => adjustTime(30)}>
					<RiForward30Line size={30} />
				</div>
			</div>
			<div className="original-control">
				<ReactAudioPlayer
					src={src}
					controls
					ref={audioRef}
					onPlay={handleOnPlay}
					onPause={handleOnPause}
					onEnded={handleOnEnded}
					className='w-100'
				/>
			</div>
			<div className="speed-control">
				{listSpeed.map((item) => (
					<div
						className={`speed-button ${speed.id === item.id ? 'active' : ''}`}
						key={item.id}
						onClick={() => handleChangeSpeed(item)}
					>
						{item.label}
					</div>
				))}
			</div>
		</div>
	);
}
export default AudioPlayer;
