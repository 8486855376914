export const activeAccordingExt = {
	pptx: 'presentation',
	ppt: 'presentation',
	pdf: 'presentation',

	mp4: 'video',
	mov: 'video',
	// "mkv" : "video",
	// "mpeg" : "video",

	mp3: 'audio',
	aac: 'audio',
	wav: 'audio',
	m4a: 'audio',
} as const;

export const SORTABLE_MOUSE_DELAY = 200;
