import React, { createContext, useCallback, useMemo } from 'react';

export const ModalScrollContext = createContext(null);

const SimpleModalScrollContextProvider = ({ children, modal }) => {
	const scrollToTop = useCallback(() => {
		modal?.scrollTo({ top: 0, behavior: 'smooth' });
	}, [modal]);

	const value = useMemo(() => ({ scrollToTop }), [scrollToTop]);

	return <ModalScrollContext.Provider value={value}>{children}</ModalScrollContext.Provider>;
};

export default SimpleModalScrollContextProvider;
