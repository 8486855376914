import SimpleModal from '../SimpleModal';
import { useCallback, useEffect, useState } from 'react';
import Button from 'components/Button';
import Step2ActivityComponent from './Step2ActivityComponent';
import { getReinforcementActivityField } from 'utils/helper';
import SuccessCompletionModal from './SuccessCompletionModal';
import { useLearnerReinforcementActivity } from 'utils/hooks/useLearnerReinforcementActivity';
import { isMobile } from 'react-device-detect';
import { useNavigation } from 'utils/hooks/useNavigation';
import { FE_ROUTES } from '@constants/feRoutes';
import { useRouter } from 'next/router';
import { useShowLearnerCompletionButton } from 'utils/hooks/useShowLearnerCompletionButton';
import { useCompleteLearnerActivity } from 'utils/hooks/useCompleteLearnerActivity';
import { LEARNING_REINFORCEMENT_ACTIVITIES, LEARNING_REINFORCEMENT_GRADED_ACTIVITIES } from '@constants/activities';
import { ReinforcementActivity } from 'types/DTOs/learnerActivityDTOs';

const ActivityModal = ({
	activities,
	showModal,
	handleModalClose,
	showSpecificActivity,
	pendingActivityDescription,
	markRead,
	refetchActivities,
}: {
	activities: ReinforcementActivity[];
	showModal: boolean;
	handleModalClose: () => void;
	showSpecificActivity: null | {
		reinforcementID: string;
		activityID: string;
		activityName: string;
		notificationId: string;
	};
	pendingActivityDescription?: string;
	markRead?: (status: { id: string; read: boolean }) => void;
	refetchActivities?: () => void;
}) => {
	const { query } = useRouter();
	const navigate = useNavigation();
	const [modalStep, setModalStep] = useState(0);
	const [canBeCompleted, setCanBeCompleted] = useState(false);
	const {
		state: { activityType, activityTypeObject, completionParams },
		getActivity,
		updateCompletionParams,
		reset,
		takeActivityLater,
	} = useLearnerReinforcementActivity();
	const showCompletionButton = useShowLearnerCompletionButton(activityType, canBeCompleted);
	const { success, completeActivity, reset: resetSuccess } = useCompleteLearnerActivity(activityType, completionParams);
	const [loading, setLoading] = useState(false);
	const [modalActivities, setModalActivities] = useState<ReinforcementActivity[]>([]);

	useEffect(() => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth',
		});
	}, []);

	useEffect(() => {
		if (activities) setModalActivities(activities);
	}, [activities]);

	useEffect(() => {
		if (showModal) {
			if (showSpecificActivity?.reinforcementID) {
				goToActivity(
					showSpecificActivity.reinforcementID,
					showSpecificActivity.activityID,
					showSpecificActivity.activityName,
					showSpecificActivity.notificationId
				);
			} else {
				setModalStep(1);
			}
		}
	}, [showSpecificActivity, showModal]);

	const closeModal = useCallback(async () => {
		if (modalStep === 1) {
			for (let i = 0; i < modalActivities.length; i++) {
				takeActivityLater(
					modalActivities[i].reinforcement._id ?? modalActivities[i].reinforcement.activity.reinforcementId,
					modalActivities[i].reinforcement.activity._id,
					modalActivities[i].reinforcement.activity.name
				);
			}
		}
		handleModalClose();
		setTimeout(() => {
			//to prevent displaying between steps
			setModalStep(1);
			reset();
			setCanBeCompleted(false);
			resetSuccess();
		}, 300);
	}, [modalStep, modalActivities]);

	const cancel = useCallback(() => {
		if (showSpecificActivity?.reinforcementID) {
			closeModal();
		} else {
			setModalStep(1);
			setCanBeCompleted(false);
		}
	}, [showSpecificActivity?.reinforcementID]);

	const goToActivity = useCallback(
		async (reinforcementID, activityID, activityName, notificationId) => {
			if (isMobile) {
				closeModal();
				navigate(FE_ROUTES.learnerReinforcementActivity, {
					reinforcementID,
					activityID,
					activityName,
					notificationId: notificationId,
				});
			} else {
				setLoading(true);
				await getActivity(reinforcementID, activityID, notificationId);
				setModalStep(2);
				setLoading(false);
			}
		},
		[isMobile]
	);

	const takeLater = useCallback(
		async (activity: ReinforcementActivity) => {
			await takeActivityLater(
				activity.reinforcement._id ?? activity.reinforcement.activity.reinforcementId,
				activity.reinforcement.activity._id,
				activity.reinforcement.activity.name
			);
			setModalActivities((prev) =>
				prev.filter((item) => item.reinforcement.activity._id !== activity.reinforcement.activity._id)
			);
			if (modalActivities.length === 1) {
				closeModal();
			}
		},
		[modalActivities?.length, modalActivities]
	);

	const TakeButtons = useCallback(
		({ activity }: { activity: ReinforcementActivity }) => (
			<div className="button-container">
				<Button onClick={() => takeLater(activity)} type="secondary">
					Take it later
				</Button>
				<Button
					onClick={() => {
						goToActivity(
							activity.reinforcement._id ?? activity.reinforcement.activity.reinforcementId,
							activity.reinforcement.activity._id,
							activity.reinforcement.activity.name,
							activity.reinforcement.notificationId
						);
					}}
				>
					Take it now
				</Button>
			</div>
		),
		[takeActivityLater, closeModal, refetchActivities, markRead, takeLater]
	);

	return success && showModal ? (
		<SuccessCompletionModal
			showModal={showModal}
			activityName={
				pendingActivityDescription
					? `Learning Reinforcement Activity - ${activityTypeObject?.name}`
					: activityTypeObject?.name
			}
			onContinue={closeModal}
		/>
	) : (
		<SimpleModal
			title={getReinforcementActivityField(activityType, 'title') ?? 'Learning Reinforcement Activity'}
			show={showModal}
			handleClose={closeModal}
			loading={loading}
		>
			<div className="activity-modal">
				{modalStep === 1 &&
					(loading ? (
						<p className="description">Loading...</p>
					) : pendingActivityDescription && modalActivities?.length === 1 ? (
						<>
							<div className="description" dangerouslySetInnerHTML={{ __html: pendingActivityDescription }} />
							<TakeButtons activity={modalActivities[0]} />
						</>
					) : (
						<>
							<p className="description">Here are the pending learning reinforcement activities:</p>
							{modalActivities?.map((reinforcementActivity, index) => (
								<div key={reinforcementActivity._id} className="list-item-container">
									<p className="description">
										{index + 1}. [
										<span className="activityType">
											{LEARNING_REINFORCEMENT_ACTIVITIES[reinforcementActivity.reinforcement.activity.type]?.title ??
												LEARNING_REINFORCEMENT_GRADED_ACTIVITIES[reinforcementActivity.reinforcement.activity.type]
													?.title}
											:{' '}
										</span>
										{reinforcementActivity.reinforcement.activity.name}]
									</p>
									<TakeButtons activity={reinforcementActivity} />
								</div>
							))}
						</>
					))}
				{modalStep === 2 && activityTypeObject && (
					<div className="learner-activity">
						<Step2ActivityComponent
							completionID={activityTypeObject.reinforcementID}
							activityType={activityType}
							activityTypeObject={activityTypeObject}
							canBeCompleted={canBeCompleted}
							completionParams={completionParams}
							setCanBeCompleted={setCanBeCompleted}
							setCompletionParams={updateCompletionParams}
							completeActivity={completeActivity}
							cancel={cancel}
							closeModal={closeModal}
						/>

						{showCompletionButton ? (
							<div className="button-container completion-btn">
								<>
									<Button
										type="secondary"
										style={{
											width: '24rem',
										}}
										disabled={!canBeCompleted}
										onClick={completeActivity}
									>
										Complete & Continue
									</Button>
								</>
							</div>
						) : null}
					</div>
				)}
			</div>
		</SimpleModal>
	);
};

export default ActivityModal;
