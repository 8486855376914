import LearnerActivityDescription from 'components/LearnerActivityDescription';
import React from 'react';
import DownloadableFilesComponent from 'components/Reinforcements/DownloadableFilesComponent';

const AbstractDownloadableFiles = ({ name, description, files, onComplete }) => {
	return (
		<div>
			<LearnerActivityDescription title={name} text={description} />
			<DownloadableFilesComponent files={files} onComplete={onComplete} />
		</div>
	);
};

export default AbstractDownloadableFiles;
