import React from 'react';
import { AnswerFeedback, ReferenceModule } from 'types/DTOs/learnerActivityDTOs';

const Feedback = ({ feedback, referenceModule }: { feedback: AnswerFeedback; referenceModule?: ReferenceModule }) => {
	return (
		<>
			{feedback?.correctAnswerFeedback?.length || feedback?.feedback?.length ? (
				<div className="feedback">
					<p className="label">Feedback:&nbsp;</p>
					<div
						dangerouslySetInnerHTML={{
							__html: feedback?.correctAnswerFeedback || feedback?.feedback,
						}}
					/>
				</div>
			) : null}
			{!!referenceModule && feedback && !feedback?.isCorrectAnswer && (
				<div className="feedback">
					<p className="label">
						Refer to:&nbsp;
						<a href={`#${referenceModule._id}`} target="_blank">
							{referenceModule.name}
						</a>
					</p>
				</div>
			)}
		</>
	);
};

export default Feedback;
