import { useCallback, useContext, useMemo, useState } from 'react';
import LearnerActivityDescription from 'components/LearnerActivityDescription';
import Button from 'components/Button';
import { api, isErrorAPIType } from '@utils/api';
import { AnswersFeedback, QuizFeedbackDTO } from 'types/DTOs/learnerActivityDTOs';
import { GenericResponseType } from 'types/DTOs/common';
import { API_ROUTES } from '@constants/api';
import SuccessContent from 'components/Reinforcements/SuccessContent';
import { ModalScrollContext } from '@contexts/modal-scroll-context';
import QuizQuestion from 'components/Reinforcements/QuizQuestion';

const AbstractQuiz = ({
	name,
	description,
	quizID,
	questions,
	completionID,
	canBeCompleted,
	referenceModule,
	cancel,
	closeModal,
	readNotification,
}) => {
	const [answers, setAnswers] = useState({});
	const [feedback, setFeedback] = useState<QuizFeedbackDTO>(null);
	const scrollContext = useContext(ModalScrollContext);
	const { scrollToTop } = scrollContext ?? {};

	const handleAnswerChange = (questionId, answer, multiple = false) => {
		let newAnswer = answer;
		if (multiple) {
			if (answers[questionId]?.includes(answer)) {
				newAnswer = answers[questionId].filter((item) => item !== answer);
			} else {
				newAnswer = [...(answers[questionId] ?? []), answer];
			}
		}

		let newAnswers = { ...answers };
		if (Array.isArray(newAnswer) && !newAnswer.length) {
			delete newAnswers[questionId];
		} else {
			newAnswers = {
				...newAnswers,
				[questionId]: newAnswer,
			};
		}
		setAnswers(newAnswers);
	};

	const handleSubmit = async () => {
		const payload = { activity: quizID, completion: completionID, submittedQuiz: [] };

		Object.keys(answers).forEach((questionID) => {
			const question = questions.find((x) => x._id === questionID);

			payload.submittedQuiz.push({
				questionId: questionID,
				sectionQuestionId: question.sectionQuestionId,
				answers: Array.isArray(answers[questionID])
					? answers[questionID].map((answerRez) => {
							return { answer: answerRez };
					  })
					: [{ answer: answers[questionID] }],
			});
		});

		const response = await api.post<GenericResponseType<QuizFeedbackDTO>>({
			endpoint: API_ROUTES.submitQuiz,
			payload,
		});
		await readNotification();
		if (!isErrorAPIType(response)) {
			setFeedback(response.data);
		}
		scrollToTop?.();
	};

	const getFeedbackById = useCallback(
		(questionId: string): AnswersFeedback | null => {
			if (feedback) {
				return feedback.submittedQuiz.find((item) => item.questionId === questionId).answers;
			}

			return null;
		},
		[feedback]
	);

	const disabledSubmit = useMemo(() => Object.keys(answers).length !== questions.length, [answers, questions]);

	const passedQuiz = useMemo(
		() =>
			feedback
				? feedback.submittedQuiz.some((quiz) =>
						quiz.questionType === 'multiple'
							? quiz.answers.length === quiz.listCorrectAnswers.length &&
							  quiz.answers.every((answer) => answer.isCorrectAnswer)
							: quiz.answers.some((answer) => answer.isCorrectAnswer)
				  )
				: false,
		[feedback]
	);

	return (
		<>
			{!!feedback && (
				<SuccessContent
					activityName={`Learning Reinforcement Activity - ${name}`}
					showCompleted={false}
					isFailed={!passedQuiz}
				/>
			)}

			<LearnerActivityDescription title={name} text={description} />
			{questions.map((data, index) => {
				return (
					<QuizQuestion
						key={data._id}
						id={data._id}
						type={data.type.code}
						questionData={data}
						index={index + 1}
						referenceModule={referenceModule}
						onChange={handleAnswerChange}
						feedback={getFeedbackById(data._id)}
					/>
				);
			})}
			{!canBeCompleted ? (
				<div className="submit-btns-container">
					<div className={!feedback ? '' : 'continue-learning'}>
						{!feedback ? (
							<>
								<Button onClick={cancel} type="secondary">
									Cancel
								</Button>
								<Button onClick={handleSubmit} disabled={disabledSubmit}>
									Submit
								</Button>
							</>
						) : (
							<Button onClick={closeModal}>Continue Learning</Button>
						)}
					</div>
				</div>
			) : null}
		</>
	);
};

export default AbstractQuiz;
