// Styles
import { Font, Card, Container, CenterImage, TextContainer } from 'components/CourseManagement/FlipCard/Styles';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useState } from 'react';

// Import Swiper styles
import 'swiper/css';

const FlipCard = (props) => {
	const { fields, courseLanguage } = props;
	const { listCard, onFlip } = fields;
	const [indexSlide, setIndexSlide] = useState(0);

	return (
		<>
			<Swiper
				className="mobile-view"
				slidesPerView={1}
				onSlideChange={(e) => setIndexSlide(e.activeIndex)}
				spaceBetween={20}
				direction="horizontal"
			>
				{listCard.map((item, index) => {
					const { selectFront, selectBack, key, type } = item;

					let cardType;

					if (type === 'frontValue') cardType = selectFront?.value || '';
					else cardType = selectBack?.value || '';

					const isText = !cardType || cardType === 'text';
					const isCenter = cardType === 'center';
					const isFull = !isText && !isCenter;

					const cardDegree = type === 'frontValue' ? '' : '-180';
					const contentDegree = cardDegree === '-180' ? '180' : '';

					return (
						<SwiperSlide key={key} style={{ padding: '2rem' }}>
							<div key={key} className="d-flex justify-content-center">
								<Card
									type={cardType}
									src={isFull ? item['url' + type] : ''}
									color="#F3F8FF"
									degree={isFull ? 0 : cardDegree}
									onClick={() => onFlip(key, type === 'frontValue' ? 'backValue' : 'frontValue')}
								>
									{isText && (
										<TextContainer
											id="description"
											className={`bg-transparent mt-3 discussions-create-form-textarea align-self-center`}
											rows="5"
											value={item[type]}
											autoFocus
											disabled
											degree={contentDegree}
										>
											<p
												dir={courseLanguage === 'ar' ? 'rtl' : 'ltr'}
												style={{ whiteSpace: 'pre-wrap', height: '100%' }}
											>
												{item[type]}
											</p>
										</TextContainer>
									)}

									{isCenter && (
										<div className="d-flex align-items-center">
											<CenterImage degree={contentDegree} style={{ userSelect: 'none' }} src={item['url' + type]} />
										</div>
									)}
								</Card>
							</div>
						</SwiperSlide>
					);
				})}
			</Swiper>
			<center className="mobile-view">
				{listCard.map((item, index) => {
					return (
						<i
							key={index}
							className={`bi bi-circle-fill m-2 ${indexSlide == index ? 'text-primary' : 'text-secondary'}`}
						></i>
					);
				})}
			</center>
			<Font
				className="mobile-view align-self-end text-center cursor-pointer mb-4 mt-4"
				color={'#969696'}
				size="1.6rem"
				weight="400"
			>
				<u>Tap anywhere on the card to flip</u>
			</Font>
			<div className="desktop-view">
				<Container isLess={!(listCard.length > 1)}>
					{listCard.map((item) => {
						const { selectFront, selectBack, key, type } = item;

						let cardType;

						if (type === 'frontValue') cardType = selectFront?.value || '';
						else cardType = selectBack?.value || '';

						const isText = !cardType || cardType === 'text';
						const isCenter = cardType === 'center';
						const isFull = !isText && !isCenter;

						const cardDegree = type === 'frontValue' ? '' : '-180';
						const contentDegree = cardDegree === '-180' ? '180' : '';
						return (
							<div key={key}>
								<Card
									type={cardType}
									src={isFull ? item['url' + type] : ''}
									color="#F3F8FF"
									degree={isFull ? 0 : cardDegree}
									onClick={() => onFlip(key, type === 'frontValue' ? 'backValue' : 'frontValue')}
								>
									{isText && (
										<TextContainer
											id="description"
											className={`bg-transparent mt-3 discussions-create-form-textarea align-self-center`}
											rows="5"
											value={item[type]}
											autoFocus
											disabled
											degree={contentDegree}
										>
											<p dir={courseLanguage === 'ar' ? 'rtl' : 'ltr'} style={{ whiteSpace: 'pre-wrap' }}>
												{item[type]}
											</p>
										</TextContainer>
									)}

									{isCenter && (
										<div className="d-flex align-items-center">
											<CenterImage degree={contentDegree} style={{ userSelect: 'none' }} src={item['url' + type]} />
										</div>
									)}
								</Card>
								<Font className="align-self-end text-center mb-4 mt-4" color={'#969696'} size="1.6rem" weight="400">
									<u>Click anywhere on the card to flip</u>
								</Font>
							</div>
						);
					})}
				</Container>
			</div>
		</>
	);
};

export default FlipCard;
