import styled from 'styled-components';

export const DownloadCard = styled.div`
	background: #ffffff;
	box-shadow: 0px 8px 12px rgba(173, 173, 173, 0.24);
	border-radius: 12px;
	padding: 1.2rem 2.2rem 1.2rem 2.2rem;
	margin-bottom: 2rem;
	display: flex;
	align-items: center;
`;

export const Font = styled.div`
	font-family: 'Titillium Web';
	font-size: ${({ size }) => size || '2rem'};
	font-weight: ${({ weight }) => weight || 600};
	color: ${({ color }) => color || 'black'};
`;
