import React, { FC, useMemo } from 'react';
import { AnswerFeedback, ReferenceModule } from 'types/DTOs/learnerActivityDTOs';
import Feedback from './Feedback';
import FeedbackMarkIcon from './FeedbackMarkIcon';

interface IScrambleSentence {
	value: string;
	statement: string;
	feedback: AnswerFeedback;
	referenceModule?: ReferenceModule;
	showFeedback?: boolean;
	handleInput: (e: React.ChangeEvent<HTMLInputElement>) => void | null;
}

const ScrambleSentence: FC<IScrambleSentence> = ({
	value,
	statement,
	feedback,
	referenceModule,
	showFeedback = true,
	handleInput,
}) => {
	const markType = useMemo(() => {
		if (feedback) {
			if (feedback.isCorrectAnswer) {
				return 'correct';
			}
			return 'wrong';
		}
	}, [feedback]);

	return (
		<div className="scramble">
			<p className="statement">{statement}</p>
			<div className="feedback-marked-row">
				{!!feedback && <FeedbackMarkIcon type={markType} />}
				<input
					type="text"
					className="fill-blank"
					value={value}
					onChange={handleInput}
					maxLength={statement.length * 2}
					disabled={!handleInput}
				/>
			</div>
			{showFeedback && <Feedback feedback={feedback} referenceModule={referenceModule} />}
		</div>
	);
};

export default ScrambleSentence;
