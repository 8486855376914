//Flip Cards
import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`
  0% {opacity: 0;}
  100% {opacity: 1;}
`;

export const TextContainer = styled.div`
	border: none;
	text-align: center;
	resize: none;
	font-family: 'Titillium Web';
	font-size: 2.4rem;
	font-weight: 600;
	transform: rotateY(${({ degree }) => degree}deg);
	animation: ${fadeIn} 1s ease;
	height: 100%;
	overflow-y: auto;
	color: #066bfb;
	user-select: none;
`;

export const Textarea = styled.textarea`
	border: none;
	text-align: center;
	resize: none;

	font-family: 'Titillium Web';
	font-size: 2.4rem;
	font-weight: 600;
	transform: rotateY(${({ degree }) => degree}deg);
	animation: ${fadeIn} 1s ease;
`;

export const Font = styled.div`
	font-family: 'Titillium Web';
	font-size: ${({ size }) => size || '2rem'};
	font-weight: ${({ weight }) => weight || 600};
	color: ${({ color }) => color || 'black'};
	transform: rotateY(${({ degree }) => degree}deg);
`;

export const CenterImage = styled.img`
	object-fit: contain;
	height: 22.6rem;
	width: 100%;
	transform: rotateY(${({ degree }) => degree}deg);
	transition: all 1s;
	animation: ${fadeIn} 1s ease;
`;

export const Card = styled.div`
	background-image: url(${({ src }) => {
		if (src && src.length > 0 && src[0] === '"') return '';
		return src;
	}});
	transition: all 0.5s ease;
	background-repeat: no-repeat;
	background-color: ${({ color }) => color || 'white'};
	background-position: center;
	background-size: ${({ type }) => (type === 'center' ? '32rem 22.6rem' : 'cover')};
	display: grid;
	${({ resize }) => (resize ? 'grid-template-rows: 10rem 1fr 1fr;' : '')}

	padding: 1.6rem 1.6rem 2.4rem;
	box-shadow: 0px 8px 12px rgba(173, 173, 173, 0.24);
	height: 42.4rem;
	border-radius: 12px;
	transform: rotateY(${({ degree }) => (typeof degree === 'string' ? degree : 0)}deg);
	user-select: none;
	@media (max-width: 56.25em) {
		width: 40rem;
	}
`;

export const Container = styled.div`
	display: grid;
	grid-template-columns: repeat(auto-fill, ${({ isLess }) => (isLess ? '32rem' : 'minmax(30rem, 1fr)')});
	gap: 2rem;
	row-gap: 4rem;

	@media (max-width: 56.25em) {
		grid-template-columns: 30rem;
	}
`;

export const UploadContainer = styled.div`
	border: 1px dashed #066bfb;
	border-radius: 1.2rem;
	padding: 1.5rem;
	height: max-content;
	position: relative;
`;
