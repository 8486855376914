import store from 'store';
import Router from 'next/router';
import { API_METHODS } from '@constants/api';
import { refreshToken } from './fetch';
import { InitialFetchState } from 'types/apiFetchContextType';

type ResponseType = 'json' | 'blob' | '';

type MethodType = (typeof API_METHODS)[keyof typeof API_METHODS];

type APIParams = {
	endpoint: string;
	payload?: any;
	putAuthToken?: boolean;
	responseType?: ResponseType;
	fileName?: string;
	baseIncluded?: boolean;
};

type CallParams = APIParams & { method: MethodType };

type ErrorAPIType = {
	status: boolean;
	message?: string;
	abort?: boolean;
	error: boolean;
};

type APICallFunction = <T = any>(params: APIParams) => Promise<T | ErrorAPIType>;
type CallFunction = <T = any>(params: CallParams) => Promise<T | ErrorAPIType>;

export const isErrorAPIType = (response: any): response is ErrorAPIType => {
	return (response as ErrorAPIType).error !== undefined;
};

export const api: Record<Lowercase<MethodType | 'download' | 'post_file'>, APICallFunction> = {
	get: <T>(params: APIParams) => callAPI<T>({ method: API_METHODS.GET, ...params }),
	post: <T>(params: APIParams) => callAPI<T>({ method: API_METHODS.POST, ...params }),
	post_file: <T>(params: APIParams) => callFileAPI<T>({ method: API_METHODS.POST, ...params }),
	patch: <T>(params: APIParams) => callAPI<T>({ method: API_METHODS.PATCH, ...params }),
	put: <T>(params: APIParams) => callAPI<T>({ method: API_METHODS.PUT, ...params }),
	delete: <T>(params: APIParams) => callAPI<T>({ method: API_METHODS.DELETE, ...params }),
	download: <T>(params: CallParams) => downloadFile<T>({ ...params }),
};

const downloadFile: CallFunction = async <T>({ endpoint, fileName = 'downloaded-file' }) => {
	try {
		const blob = await api.get<Blob>({ endpoint, responseType: 'blob', baseIncluded: true });

		if (blob instanceof Blob) {
			const blobUrl = URL.createObjectURL(blob);

			const anchor = document.createElement('a');
			anchor.href = blobUrl;
			anchor.download = fileName;
			anchor.click();

			URL.revokeObjectURL(blobUrl);
		} else {
			console.error('File download failed: Unexpected response');
		}
		return { status: true } as T;
	} catch (error) {
		console.error('File download failed:', (error as any).message);
		return { status: false, message: (error as any).message || 'Failed', error: true };
	}
};

const callAPI: CallFunction = async <T>({
	method,
	endpoint,
	payload = {},
	putAuthToken = true,
	responseType = '',
	baseIncluded = false,
}) => {
	let header = {
		'Content-Type': 'application/json',
		Authorization: '',
		AllowedOrigin: '*',
	};

	if (putAuthToken) {
		let lerero_lms = store.get('lerero_lms');
		if (lerero_lms?.accessToken) {
			header.Authorization = 'Bearer ' + lerero_lms.accessToken;
		}
	}
	const reqInit: RequestInit = {
		method,
		headers: header,
	};
	let response: Response;

	try {
		const url = baseIncluded ? endpoint : process.env.NEXT_PUBLIC_API_URL + endpoint;
		if (method.toUpperCase() === API_METHODS.GET) {
			response = await fetch(url, reqInit);
		} else {
			response = await fetch(url, {
				...reqInit,
				body: JSON.stringify(payload),
			});
		}
		if (responseType === 'blob') {
			return await response.blob();
		}

		let res = await response.json();

		const statusCode = res.statusCode;
		const errorMessage = res.errors;

		if (statusCode === 500) {
			return {
				status: false,
				message: res?.message,
				abort: false,
				error: true,
			};
		}

		if (statusCode === 403 && endpoint !== 'auth/login' && errorMessage === 'Forbidden resource') {
			return handle403Error(res);
		}

		if (statusCode === 401 && endpoint !== 'auth/login') {
			const statusRefreshToken = await refreshToken();
			if (statusRefreshToken) {
				// Refetch
				return callAPI({ method, endpoint, payload });
			}
		}
		return res as T;
	} catch (e) {
		console.warn(e);
		if ((e as any).name === 'AbortError') {
			return {
				status: false,
				message: 'Request aborted',
				abort: true,
				error: true,
			};
		} else {
			return {
				status: false,
				message: (e as any).message,
				abort: false,
				error: true,
			};
		}
	}
};

const callFileAPI: CallFunction = async ({ method, endpoint, payload, putAuthToken = true }) => {
	let headers: { Authorization?: string } = {};
	if (putAuthToken) {
		let lerero_lms = store.get('lerero_lms');
		if (lerero_lms?.accessToken) {
			headers.Authorization = 'Bearer ' + lerero_lms?.accessToken;
		}
	}
	try {
		let response: Response = await fetch(process.env.NEXT_PUBLIC_API_URL + endpoint, {
			method: method,
			headers: headers,
			body: payload,
		});

		let res = await response.json();
		const errorCode = res.statusCode;
		const errorMessage = res.errors;

		if (errorCode === 403 && endpoint !== 'auth/login' && errorMessage === 'Forbidden resource') {
			return handle403Error(res);
		}
		if (errorCode === 401) {
			const statusRefreshToken = await refreshToken();
			if (statusRefreshToken) {
				// Refetch
				return callFileAPI({ method, endpoint, payload });
			}
		}
		return res;
	} catch (error) {
		if (error.name === 'AbortError') {
			return {
				status: false,
				message: 'Request aborted',
				abort: true,
				error: true,
			};
		} else {
			return {
				status: false,
				message: error.message,
				abort: false,
				error: true,
			};
		}
	}
};

const handle403Error = (res) => {
	const dataLms = store.get('lerero_lms') ?? '';
	const alreadyError = store.get('already-error') ?? false;

	const removeAlert = () => {
		const originalAlert = window.alert;
		window.alert = function () {};
		setTimeout(() => {
			window.alert = originalAlert;
		}, 10);
	};

	const handleDoNotHaveAccess = () => {
		store.set('already-error', true);
		store.set('is-dont-have-access', true);
		removeAlert();
	};

	const specialRoleCondition =
		dataLms && (dataLms.user.roleType === 'facilitator' || dataLms.user.roleType === 'examiner');

	if (
		!alreadyError &&
		((specialRoleCondition && Router.asPath === '/course_management') || Router.asPath === '/dashboard')
	) {
		Router.reload();
		handleDoNotHaveAccess();
		return res;
	}

	if (specialRoleCondition) {
		Router.push({ pathname: '/course_management' });
	} else {
		Router.push({ pathname: '/dashboard' });
	}

	handleDoNotHaveAccess();
	return res;
};

export const getValidRecords = (fetchRecord: InitialFetchState) =>
	Object.entries(fetchRecord).filter(([key, item]) => !item.url.includes('undefined') && !item.url.includes('//'));
