import React from "react"
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import { useRef, useState, useEffect } from "react";

export const VideoJS = (props) => {
  const videoRef = React.useRef(null);
  const playerRef = React.useRef(null);
  const {options, onReady} = props;
  const [currentTime, setCurrentTime] = useState(0);
  const [videoDuration, setVideoDuration] = useState(200); // Set the duration of the video in seconds
  const threshold = 0.95; // Set the threshold to 95% of the video duration

  function handleTimeUpdate(event) {
    setVideoDuration(event.target.duration);
    setCurrentTime(event.target.currentTime);
  }

  if (currentTime > videoDuration * threshold) {
    // This code will execute when the progress bar is dragged to near the end of the video
    console.log('Video has been forcefully dragged to the end');
  }
  

  React.useEffect(() => {

    // Make sure Video.js player is only initialized once
    if (!playerRef.current) {
      const videoElement = videoRef.current;

      if (!videoElement) return;

      const player = playerRef.current = videojs(videoElement, options, () => {
        videojs.log('player is ready');
        onReady && onReady(player);
      });

    // You could update an existing player in the `else` block here
    // on prop change, for example:
    } else {
      const player = playerRef.current;
      const handleFullscreenChange = () => {
        if (document.fullscreenElement) {
          if (screen.orientation && screen.orientation.lock) {
            screen.orientation.lock("landscape").catch((err) => {
              console.error("Failed to lock display orientation:", err);
            });
          }
        } else {
          if (screen.orientation && screen.orientation.unlock) {
            screen.orientation.unlock();
          }
        }
      };
      player.autoplay(options.autoplay);
      player.src(options.sources);
      player.on("fullscreenchange", handleFullscreenChange);

    return () => {
      if (player) {
        player.off("fullscreenchange", handleFullscreenChange);
        // player.dispose();
      }
    };
    }
  }, [options, videoRef]);

  // Dispose the Video.js player when the functional component unmounts
  React.useEffect(() => {
    const player = playerRef.current;

    return () => {
      if (player) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, [playerRef]);

  return (
    <div data-vjs-player>
      <video ref={videoRef} className='video-js vjs-big-play-centered' onTimeUpdate={handleTimeUpdate} />
    </div>
  );
}

export default VideoJS;