import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { OrderedItemType } from 'types/activities';
import { SORTABLE_MOUSE_DELAY } from '@constants/common';

interface IOrderedItem {
	item: OrderedItemType;
	children?: React.ReactNode;
}

const OrderedItem: FC<IOrderedItem> = ({ item, children }) => {
	const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: item.id });
	const [pressedCursor, setPressedCursor] = useState(false);
	const timerRef = useRef(null);

	const handleMouseDown = useCallback(() => {
		timerRef.current = setTimeout(() => {
			setPressedCursor(true);
		}, SORTABLE_MOUSE_DELAY);
	}, []);

	const handleMouseUp = useCallback(() => {
		clearTimeout(timerRef.current);
		setPressedCursor(false);
	}, []);

	useEffect(() => () => clearTimeout(timerRef.current), []);

	const style = {
		transform: `translate3d(0px, ${transform?.y ?? 0}px, 0) scaleX(1) scaleY(1)`,
		transition,
		width: '100%',
	};

	return (
		<div ref={setNodeRef} style={style} {...attributes} {...listeners}>
			<div
				className={`sortable-row ${pressedCursor ? 'grab' : ''}`}
				onMouseDown={handleMouseDown}
				onMouseUp={handleMouseUp}
			>
				<img src={'/drag_icon.svg'} alt="drag" />
				{children ?? <p>{item.content}</p>}
			</div>
		</div>
	);
};

export default OrderedItem;
