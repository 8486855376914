const Icons = (props) => {

  const { type, active } = props

  const color = active ? "#066BFB" : "#969696"

  if (type === "audio") return (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill={color} fill-rule="evenodd" clip-rule="evenodd" d="M21 6.97781V6.67781C21 5.73281 21.945 5.05781 22.815 5.40281C27.9 7.33781 31.5 12.2428 31.5 18.0028C31.5 23.7628 27.9 28.6678 22.815 30.6028C21.945 30.9328 21 30.2728 21 29.3278V29.0278C21 28.4578 21.36 27.9628 21.9 27.7528C25.77 26.2078 28.5 22.4128 28.5 18.0028C28.5 13.5928 25.77 9.79781 21.9 8.25281C21.375 8.04281 21 7.54781 21 6.97781ZM4.5 15.0028V21.0028C4.5 21.8278 5.175 22.5028 6 22.5028H10.5L15.435 27.4378C16.38 28.3828 18 27.7078 18 26.3728V9.61781C18 8.28281 16.38 7.60781 15.435 8.55281L10.5 13.5028H6C5.175 13.5028 4.5 14.1778 4.5 15.0028ZM24.75 18.0028C24.75 15.3478 23.22 13.0678 21 11.9578V24.0328C23.22 22.9378 24.75 20.6578 24.75 18.0028Z"/>
    </svg>
  )
  if (type === "file") return (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill={color} fill-rule="evenodd" clip-rule="evenodd" d="M6.015 6C6.015 4.35 7.35 3 9 3H19.755C20.55 3 21.315 3.315 21.87 3.885L29.115 11.13C29.685 11.685 30 12.45 30 13.245V30C30 31.65 28.65 33 27 33H8.985C7.335 33 6 31.65 6 30L6.015 6ZM19.5 5.25V12C19.5 12.825 20.175 13.5 21 13.5H27.75L19.5 5.25Z"/>
    </svg>
  )
  if (type === "record") return (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill={color} d="M18 6C11.3726 6 6 11.3726 6 18C6 24.6274 11.3726 30 18 30C24.6274 30 30 24.6274 30 18C30 11.3726 24.6274 6 18 6Z" />
    </svg>
  )
  if (type === "text") return (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill={color} fill-rule="evenodd" clip-rule="evenodd" d="M34.5 3V9C34.5 9.825 33.825 10.5 33 10.5H31.5V25.5H33C33.825 25.5 34.5 26.175 34.5 27V33C34.5 33.825 33.825 34.5 33 34.5H27C26.175 34.5 25.5 33.825 25.5 33V31.5H10.5V33C10.5 33.825 9.825 34.5 9 34.5H3C2.175 34.5 1.5 33.825 1.5 33V27C1.5 26.175 2.175 25.5 3 25.5H4.5V10.5H3C2.175 10.5 1.5 9.825 1.5 9V3C1.5 2.175 2.175 1.5 3 1.5H9C9.825 1.5 10.5 2.175 10.5 3V4.5H25.5V3C25.5 2.175 26.175 1.5 27 1.5H33C33.825 1.5 34.5 2.175 34.5 3ZM7.5 4.5H4.5V7.5H7.5V4.5ZM7.5 31.5H4.5V28.5H7.5V31.5ZM10.5 27V28.5H25.5V27C25.5 26.175 26.175 25.5 27 25.5H28.5V10.5H27C26.175 10.5 25.5 9.825 25.5 9V7.5H10.5V9C10.5 9.825 9.825 10.5 9 10.5H7.5V25.5H9C9.825 25.5 10.5 26.175 10.5 27ZM31.5 31.5H28.5V28.5H31.5V31.5ZM28.5 4.5V7.5H31.5V4.5H28.5ZM19.41 11.475C19.185 10.89 18.615 10.5 17.985 10.5C17.355 10.5 16.785 10.89 16.575 11.475L12.42 22.47C12.135 23.205 12.675 24 13.47 24C13.95 24 14.37 23.7 14.535 23.25L15.36 21H20.595L21.435 23.265C21.6 23.7 22.02 24 22.5 24H22.515C23.31 24 23.85 23.205 23.58 22.47L19.41 11.475ZM18 13.365L16.035 19.11H19.95L18 13.365Z" />
    </svg>
  )
  if (type === "video") return (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill={color} fill-rule="evenodd" clip-rule="evenodd" d="M3 18C3 9.72 9.72 3 18 3C26.28 3 33 9.72 33 18C33 26.28 26.28 33 18 33C9.72 33 3 26.28 3 18ZM16.2 12.15C15.705 11.775 15 12.135 15 12.75V23.25C15 23.865 15.705 24.225 16.2 23.85L23.205 18.6C23.61 18.3 23.61 17.7 23.205 17.4L16.2 12.15Z" />
    </svg>
  )
  if (type === "presentation") return (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill={color} fill-rule="evenodd" clip-rule="evenodd" d="M16.6245 31.2298C16.6245 32.1898 15.7395 32.9098 14.8095 32.6998C8.10451 31.1998 3.12451 25.1848 3.12451 17.9998C3.12451 10.8148 8.10451 4.79981 14.8095 3.29981C15.7395 3.08981 16.6245 3.80981 16.6245 4.76981V31.2298ZM19.6695 4.76981V14.9848C19.6695 15.8098 20.3445 16.4848 21.1695 16.4848H31.3545C32.3145 16.4848 33.0345 15.5998 32.8245 14.6548C31.5495 9.01481 27.1245 4.57481 21.4995 3.29981C20.5545 3.08981 19.6695 3.80981 19.6695 4.76981ZM19.6695 31.2298V21.0148C19.6695 20.1898 20.3445 19.5148 21.1845 19.4998H31.3695C32.3145 19.4998 33.0495 20.3998 32.8395 21.3298C31.5645 26.9698 27.1395 31.4248 21.4995 32.6998C20.5545 32.9098 19.6695 32.1898 19.6695 31.2298Z"/>
    </svg>
  )
}

export default Icons