import { createContext, Dispatch, SetStateAction, useState } from 'react';

export const ReinforcementNotificationModalContext = createContext<[string, Dispatch<SetStateAction<string>>] | null>(
	null
);

const ReinforcementNotificationModalContextProvider = ({ children }) => {
	const [reinforcementNotifcation, setreinforcementNotifcation] = useState('');

	return (
		<ReinforcementNotificationModalContext.Provider value={[reinforcementNotifcation, setreinforcementNotifcation]}>
			{children}
		</ReinforcementNotificationModalContext.Provider>
	);
};

export default ReinforcementNotificationModalContextProvider;
