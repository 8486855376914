import React, { useState, useEffect, useRef } from 'react';
import store from 'store';

function Editor({
	disabled,
	onChange,
	editorLoaded = true,
	name,
	value,
	hide,
	placeholder = '',
	narrowMode = false,
	isHtmlDisabled = false,
	language = 'en',
}) {
	const editorRef = useRef();
	const [role, setRole] = useState('');
	const [key, setKey] = useState(0);
	const [textAlignSetting, setTextAlignSetting] = useState(language);
	const { CKEditor, ClassicEditor } = editorRef.current || {};

	const lerero_lms = store.get('lerero_lms');

	useEffect(() => {
		editorRef.current = {
			CKEditor: require('@ckeditor/ckeditor5-react').CKEditor, // v3+
			ClassicEditor: require('src/ckeditor5-build-classic-custom'),
		};

		let user = localStorage.getItem('lerero_lms');
		user = JSON.parse(user);
		setRole(user.user.roles);
	}, []);

	useEffect(() => {
		setTextAlignSetting(language);
		setKey(key + 1);
	}, [language]);

	return (
		<div className="position-relative">
			{editorLoaded && editorRef.current ? (
				<div className={`${hide ? 'display-none' : ''} ${narrowMode ? 'narrow-editor' : ''}`}>
					<CKEditor
						key={key}
						type=""
						name={name}
						disabled={disabled}
						editor={ClassicEditor}
						data={value}
						config={{
							toolbar: isHtmlDisabled
								? []
								: [
										'bold',
										'italic',
										'underline',
										'strikethrough',
										'link',
										'bulletedList',
										'numberedList',
										'|',
										'alignment',
										'outdent',
										'indent',
										'|',
										'fontSize',
										'fontColor',
										'|',
										'blockQuote',
										'insertTable',
										'undo',
										'redo',
										'superscript',
										'subscript',
										'removeFormat',
										'specialCharacters',
										'imageInsert',
										'htmlEmbed',
								  ],
							language: {
								ui: 'en',
								content: textAlignSetting,
							},
							link: {
								// Automatically add target="_blank" and rel="noopener noreferrer" to all external links.
								addTargetToExternalLinks: true,
							},
							fontSize: {
								options: [12, 14, 16, 'default', 20, 22, 24, 26, 28],
							},
							simpleUpload: {
								// The URL that the images are uploaded to.
								uploadUrl: role === 'ADMIN' ? '/upload-admin' : '/upload-learner',
								// Enable the XMLHttpRequest.withCredentials property.
								withCredentials: true,
								// Headers sent along with the XMLHttpRequest to the upload server.
								headers: {
									AllowedOrigin: '*',
									Authorization: `Bearer ${lerero_lms?.accessToken}`,
								},
							},
							placeholder,
						}}
						onChange={(event, editor) => {
							const data = editor.getData();
							onChange(data);
						}}
					/>
				</div>
			) : (
				<div>Editor loading...</div>
			)}
		</div>
	);
}

export default Editor;
