import { LEARNING_REINFORCEMENT_ACTIVITIES, LEARNING_REINFORCEMENT_GRADED_ACTIVITIES } from '@constants/activities';
import { CalendarDTO, ReinforcementActivityType } from 'types/DTOs/activityDTOs';
import _ from 'lodash';
import { formatDuration, intervalToDuration } from 'date-fns';
import { useRouter } from 'next/router';
import moment from 'moment-timezone';

export const thousandSeparator = (text) => {
	return text !== null && text !== undefined ? text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '';
};

export const getReinforcementActivityField = (
	activityType: ReinforcementActivityType,
	field: 'route' | 'title' | 'thumbnail' = 'route'
) => {
	return (
		LEARNING_REINFORCEMENT_GRADED_ACTIVITIES[activityType]?.[field] ??
		LEARNING_REINFORCEMENT_ACTIVITIES[activityType]?.[field]
	);
};

export const getRandomKey = (text?: string) => {
	const key = Math.floor(Math.random() * 10000000) + 1;
	return text ? `${text}-${key}` : key.toString();
};

export const arraysAreDifferentByField = (arr1: any[], arr2: any[], field: string) => {
	const difference1 = _.differenceBy(arr1 || [], arr2 || [], field);
	const difference2 = _.differenceBy(arr2 || [], arr1 || [], field);
	return difference1.length > 0 || difference2.length > 0;
};

// export const getMaxCalendarWeek = (calendar: CalendarDTO) =>
// 	calendar.reduce((max, item) => Math.max(max, item.week), 0);

// export const filterObjectByKeys = <K extends keyof T, T extends object = {}>(keyArray: K[], originalObject: T) => {
// 	return keyArray.reduce((acc, key) => {
// 		if (originalObject.hasOwnProperty(key)) {
// 			acc[key] = originalObject[key];
// 		}
// 		return acc;
// 	}, {} as Pick<T, K>);
// };

export const parseMillisecondsReadable = (milliseconds: number) => {
	const duration = intervalToDuration({ start: 0, end: milliseconds ?? 0 });

	if (duration.minutes > 0) {
		return `${duration.minutes} min${duration.minutes !== 1 ? 's' : ''}`;
	}
	return `${duration.seconds} s`;
};

export const isPast = (dateTime, timezone: string) => {
	const currentTime = moment();
	const selectedTime = moment.tz(dateTime, timezone);
	return selectedTime.isSameOrBefore(currentTime);
};
