import ReactAudioPlayer from "react-audio-player";
const LessonAudioPreview = (props) => {
  
    const { fileUrl, setIsDisabled } = props
    let url = fileUrl?.path || fileUrl || ""
    if (!url || typeof url === "object") url = fileUrl ? URL.createObjectURL(fileUrl) : ""
  
    return (
      <div className="row">
        <center>
          <img className="desktop-view" src="/library/music_soundbar.png" width="50%" />
          <img className="mobile-view" src="/library/icon_audio.gif" />
        </center>
        <ReactAudioPlayer
          onEnded={() => setIsDisabled(false)}
          src={url}
          controls
          style={{width:"100%"}}
        />
      </div>
    )
  }

export default LessonAudioPreview