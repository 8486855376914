import LearnerActivityDescription from 'components/LearnerActivityDescription';
import VideoPlayer from '../Dashboard/Video';
import { Font } from '../Dashboard/Styles';

const AbstractVideo = ({ name, description, fileURL, onComplete }) => {
	return (
		<>
			<LearnerActivityDescription title={name} text={description} />
			<VideoPlayer
				videoFile={fileURL}
				subtitle={false}
				setIsDisabled={() => {
					onComplete();
				}}
			/>
			<Font
				color="#066BFB"
				weight="400"
				size="1.6rem"
				style={{ padding: '1rem', backgroundColor: '#F3F8FF', borderRadius: '.7rem' }}
				className="mt-3"
			>
				The &#39;Complete and Continue&#39; button will be enabled after you finish the video
			</Font>
		</>
	);
};

export default AbstractVideo;
