import VideoJS from 'utils/video';
import ReactPlayer from 'react-player';
import { useRef, useState } from 'react';
import { Font } from '../Styles';
import Skeleton from 'react-loading-skeleton';

const LessonVideoPreview = (props) => {
	const [isEverRender, setIsEverRender] = useState(false);
	const { fileUrl: videoFile = '', fileSubtitle, setIsDisabled } = props;

	let videoSrc = videoFile;

	if (videoFile && videoFile.path) videoSrc = videoFile.path;
	else if (videoFile && typeof videoFile !== 'string') videoSrc = URL.createObjectURL(videoFile);

	const playerRef = useRef(null);

	const videoJsOptions = {
		autoplay: false,
		controls: true,
		responsive: true,
		playbackRates: [0.5, 1, 1.5, 2],
		fluid: true,
		sources: [
			{
				src: videoSrc,
				type: 'video/mp4',
			},
		],
		tracks: fileSubtitle
			? [
					{
						src: fileSubtitle,
						kind: 'captions',
						label: 'Subtitle',
						default: true,
					},
			  ]
			: '',
	};

	const handlePlayerReady = (player) => {
		playerRef.current = player;

		// You can handle player events here, for example:
		player.on('waiting', () => {
			videojs.log('player is waiting');
		});

		player.on('dispose', () => {
			videojs.log('player will dispose');
		});

		player.on('ended', () => {
			setIsDisabled(false);
		});
	};

	if (!isEverRender) {
		setTimeout(() => {
			setIsEverRender(true);
		}, 500);
		return <Skeleton style={{ height: '70rem', width: '100%' }} />;
	}

	if (videoSrc.includes('youtu'))
		return (
			<div>
				<div className="desktop-view">
					<ReactPlayer url={videoSrc} onEnded={() => setIsDisabled(false)} controls={true} width="100%" height="80vh" />
				</div>
				<div className="mobile-view">
					<ReactPlayer url={videoSrc} onEnded={() => setIsDisabled(false)} controls={true} width="100%" />
				</div>
				<Font
					color="#066BFB"
					weight="400"
					size="1.6rem"
					style={{ padding: '1rem', backgroundColor: '#F3F8FF', borderRadius: '.7rem' }}
					className="mt-3"
				>
					The &#39;Complete and Continue&#39; button will be enabled after you finish the video
				</Font>
			</div>
		);
	return (
		<>
			<VideoJS options={videoJsOptions} onReady={handlePlayerReady} />
			<Font
				color="#066BFB"
				weight="400"
				size="1.6rem"
				style={{ padding: '1rem', backgroundColor: '#F3F8FF', borderRadius: '.7rem' }}
				className="mt-3"
			>
				The &#39;Complete and Continue&#39; button will be enabled after you finish the video
			</Font>
		</>
	);
};

export default LessonVideoPreview;
